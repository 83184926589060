import React, { useState } from "react";

import styles from "./styles.module.css";
import { Button, Card, Form, FormProps, Input, Typography } from "antd";
import { recoverPassword } from "../../services/userFunctions";
import useMessage from "antd/lib/message/useMessage";

type FieldType = {
  email?: string;
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const RecoverPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [message, contextHolder] = useMessage();

  const onFinish: FormProps<{ email: string }>["onFinish"] = async (values) => {
    setLoading(true);

    const loginResponse = await recoverPassword(values.email);
    if (!loginResponse.isError) {
      message.success(
        `Uma nova senha foi enviada para o e-mail ${values.email}`
      );
    } else {
      message.error(
        "Ocorreu um erro. Verifique sua conexão com a internet e se seu e-mail está correto!"
      );
    }

    setLoading(false);
  };
  return (
    <div className={styles.main}>
      {contextHolder}
      <div className={styles.background}></div>
      <main className={styles.loginContainer}>
        <Card className={styles.card}>
          <img src="/logo-full.png" alt="logo" className={styles.logo} />
          <Typography.Title level={4}>Recuperação de senha</Typography.Title>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item<FieldType>
              label="Informe seu e-mail cadastrado e enviaremos uma nova senha para seu e-mail"
              name="email"
              rules={[
                { required: true, message: "Insira seu e-mail!" },
                {
                  pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                  message: "E-mail inválido!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Recuperar senha
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </main>
    </div>
  );
};

export default RecoverPassword;
