import React from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form } from "antd";
import { Button, Input, Radio, Space } from "antd/lib";
import { RiShieldFill } from "react-icons/ri";

const onFinish = (values: any) => {
  console.log("Received values of form: ", values);
};

const EditAdmin: React.FC = () => {
  return (
    <AppLayout title="Adicionar administrador">
      <MainContent>
        <Form
          className={styles.form}
          name="complex-form"
          onFinish={onFinish}
          labelCol={{ span: 32 }}
          wrapperCol={{ span: 32 }}
          layout="vertical"
        >
          <Form.Item
            label={
              <>
                <RiShieldFill style={{ marginRight: 8 }} /> Login do
                Administrador
              </>
            }
          >
            <Space wrap>
              <Form.Item
                name="type"
                label="Tipo de Administrador"
                rules={[{ required: true, message: "Selecione um tipo" }]}
              >
                <Radio.Group>
                  <Radio.Button value="Admin">Administrador</Radio.Button>
                  <Radio.Button value="Arena">Arena</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="fullName"
                label="Nome completo"
                rules={[
                  { required: true, message: "Nome completo obrigatório" },
                ]}
              >
                <Input placeholder="Nome completo" />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[{ required: true, message: "E-mail obrigatório" }]}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label="Celular"
                rules={[
                  { required: true, message: "Número de celular obrigatório" },
                ]}
              >
                <Input placeholder="99 99999-9999" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Senha"
                rules={[{ required: true, message: "Digite uma senha" }]}
              >
                <Input.Password placeholder="Senha" />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item label=" " noStyle colon={false}>
            <Button type="primary" htmlType="submit">
              Salvar administrador
            </Button>
          </Form.Item>
        </Form>
      </MainContent>
    </AppLayout>
  );
};

export default EditAdmin;
