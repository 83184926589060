import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./styles.module.css";
import { GetUserVideoResponse } from "../../services/responses";
import { getUserVideo } from "../../services/userFunctions";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { Skeleton, Button } from "antd/lib";
import dayjs from "dayjs";
import { DownloadOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Space } from "antd";

const VideoPlayer: React.FC = () => {
  const { userId, videoId, purchaseId } = useParams();
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [videoData, setVideoData] = useState<GetUserVideoResponse | undefined>(
    undefined
  );

  const getVideo = useCallback(async () => {
    setLoadingVideo(true);
    const video = await getUserVideo({
      userId: String(userId),
      videoId: String(videoId),
      purchaseId: String(purchaseId),
    });
    if (!video.isError) {
      const res = video as GetUserVideoResponse;
      setVideoData(res);
    } else {
      //TODO tratar erro
    }
    setLoadingVideo(false);
  }, [purchaseId, userId, videoId]);

  useEffect(() => {
    getVideo();
  }, [getVideo]);

  const handleShare = async () => {
    const url = window.location.href; // Get the current page URL
    const title = "Veja este vídeo incrível!";
    const text = "Confira o vídeo na plataforma Futlance";

    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text,
          url,
        });
      } catch (error) {
        console.error("Erro ao compartilhar:", error);
      }
    } else {
      try {
        await navigator.clipboard.writeText(url);
        alert("Link copiado para a área de transferência!");
      } catch (error) {
        console.error("Erro ao copiar o link:", error);
      }
    }
  };

  const handleDownload = async () => {
    if (videoData?.uri) {
      setLoadingDownload(true);
      try {
        const day = dayjs(videoData.recordedAt).format("DD_MM_YY_HH_mm");
        const fileName = `video_${day}_${videoData.arenaName}_${videoData.fieldDescription}.mp4`;
        const response = await fetch(videoData.uri, { mode: "cors" });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download video", error);
      } finally {
        setLoadingDownload(false);
        alert(
          "Download iniciado! Confira sua pasta de Downloads ou app Arquivos!"
        );
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.playerActions}>
        <div className="listWrapper" id="left_header">
          {/* <Avatar /> */}
          {loadingVideo ? (
            <Skeleton.Button />
          ) : (
            <div className="texts">
              <h1 className="listTitle">{videoData?.name}</h1>
              <h2 className="listSubtitle">
                Gravado em {dayjs(videoData?.recordedAt).format("DD/MM/YYYY")}{" "}
                às {dayjs(videoData?.recordedAt).format("HH:mm")}
              </h2>
            </div>
          )}
        </div>
        <Link to={"/"} className={styles.logoWrapper}>
          <img src="/logo-full.png" alt="logo" className={styles.logo} />
        </Link>

        <div className="listWrapper" id="right_header">
          {loadingVideo ? (
            <Skeleton.Button />
          ) : (
            <div className="texts">
              <h1 className="listTitle" style={{ textAlign: "right" }}>
                {videoData?.arenaName}
              </h1>
              <h2 className="listSubtitle" style={{ textAlign: "right" }}>
                {videoData?.fieldDescription}
              </h2>
            </div>
          )}
        </div>
      </div>
      <div className={styles.videoWrapper}>
        {loadingVideo ? (
          <Loader />
        ) : (
          <ReactPlayer controls={true} url={videoData?.uri} />
        )}
      </div>

      {!loadingVideo && videoData?.uri && (
        <Space>
          <Button
            type="default"
            onClick={handleDownload}
            size="small"
            disabled={loadingDownload}
            icon={<DownloadOutlined />}
          >
            {loadingDownload ? "Baixando..." : "Baixar"}
          </Button>

          <Button
            type="default"
            onClick={handleShare}
            size="small"
            icon={<ShareAltOutlined />}
          >
            Compartilhar
          </Button>
        </Space>
      )}
      <footer className={styles.footer}>
        Futlance 2024 © CNPJ: 12.456.789/00001-12
      </footer>
    </div>
  );
};

export default VideoPlayer;
