import { Avatar, Badge, Card, Image } from "antd/lib";
import Meta from "antd/lib/card/Meta";
import React, { useState } from "react";
import styles from "./styles.module.css";
import { Button } from "antd";
import {
  DownloadOutlined,
  EyeFilled,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { User, Video as VideoType } from "../../services/types";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../auth/userService";

type VideoProps = {
  onAddToCard: () => void;
  video: VideoType;
  enableOwnerOptions?: boolean;
};

const Video: React.FC<VideoProps> = ({
  onAddToCard,
  video,
  enableOwnerOptions,
}) => {
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleDownload = async (video: VideoType) => {
    if (video?.uri) {
      setLoadingDownload(true);
      try {
        const day = dayjs(video.recordedAt).format("DD_MM_YY_HH_mm");
        const fileName = `video_${day}_${video.arenaName}_${video.fieldDescription}.mp4`;
        const response = await fetch(video.uri, { mode: "cors" });
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Failed to download video", error);
      } finally {
        setLoadingDownload(false);
        alert(
          "Download iniciado! Confira sua pasta de Downloads ou app Arquivos!"
        );
      }
    }
  };

  const navigate = useNavigate();
  const session = getLoggedUser();
  const userIsLogged = session && typeof session !== "string";

  return (
    <Badge.Ribbon
      text={`${userIsLogged ? Number(session.discount) : 0}% de desconto`}
      style={{
        display:
          enableOwnerOptions &&
          userIsLogged &&
          session.discount &&
          parseFloat(String(session.discount)) > 0
            ? "none"
            : "inline",
        top: 0,
      }}
      color="volcano"
      placement="start"
    >
      <Card
        extra={
          !enableOwnerOptions &&
          userIsLogged && (
            <Badge.Ribbon
              text={
                parseFloat(String(session.discount)) > 0 ? (
                  <>
                    R$
                    <span
                      style={{
                        textDecoration:
                          video.videoPrice &&
                          parseFloat(String(video.videoPrice)) > 0
                            ? "line-through white"
                            : "none",
                      }}
                    >
                      {parseFloat(String(video.videoPrice)).toFixed(2)}
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      R$
                      {(
                        parseFloat(String(video.videoPrice)) -
                        parseFloat(String(video.videoPrice)) *
                          (parseFloat(String(session.discount)) / 100)
                      ).toFixed(2)}
                    </span>
                  </>
                ) : (
                  <span>
                    R$ {parseFloat(String(video.videoPrice)).toFixed(2)}
                  </span>
                )
              }
              color="green"
              style={{
                top: 0,
                left: -20,
                zIndex: 4,
              }}
              placement="start"
            ></Badge.Ribbon>
          )
        }
        className={styles.videoCard}
        type="inner"
        cover={
          <Image
            src={video.preview}
            placeholder={<Image preview={false} src="/image.png" />}
          />
        }
        actions={
          enableOwnerOptions
            ? [
                <Button
                  block
                  icon={<EyeFilled />}
                  onClick={() =>
                    navigate(
                      `/video/${String((session as User).id)}/${video.id}/${
                        video.purchaseId
                      }`
                    )
                  }
                >
                  Assistir
                </Button>,

                <Button
                  block
                  icon={<DownloadOutlined />}
                  onClick={() => handleDownload(video)}
                >
                  {loadingDownload ? "Baixando..." : "Baixar"}
                </Button>,
              ]
            : [
                <Button
                  block
                  icon={<ShoppingCartOutlined />}
                  onClick={() => onAddToCard()}
                >
                  Adicionar ao carrinho
                </Button>,
              ]
        }
      >
        <Meta
          avatar={<Avatar shape="square" src={video.arenaLogo} />}
          title={`${video.arenaName} - ${video.fieldDescription}`}
          description={`Gravado em ${dayjs(video.recordedAt).format(
            "DD/MM/YY"
          )} às ${dayjs(video.recordedAt).format("HH:mm")}`}
        />
      </Card>
    </Badge.Ribbon>
  );
};

export default Video;
