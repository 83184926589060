import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "../pages/login";
import Customers from "../pages/customers";
import RecoverPassword from "../pages/recoverPassword";
import Register from "../pages/register";
import Users from "../pages/admins";
import Plans from "../pages/plans";
import Sales from "../pages/sales";
import Arenas from "../pages/arenas";
import EditCustomer from "../pages/editCustomer";
import EditAdmin from "../pages/editAdmin";
import EditPlan from "../pages/editPlan";
import EditArena from "../pages/editArena";
import EditField from "../pages/editField";
import EditAd from "../pages/editAd";
import Settings from "../pages/settings";
import Videos from "../pages/videos";
import Profile from "../pages/profile";
import PlansList from "../pages/plansList";
import VideoPlayer from "../pages/videoPlayer";
import Purchase from "../pages/purchase";
import MyVideos from "../pages/myVideos";
import { getLoggedUser } from "../auth/userService";
import FourOhFOur from "../pages/401";

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const user = getLoggedUser();

  if (user && typeof user !== "string" && user.type === "PRIVILEGED") {
    return children;
  } else {
    return <Navigate to="/401" replace />;
  }
};

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const user = getLoggedUser();

  if (user && typeof user !== "string") {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/recover-password",
    element: <RecoverPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/admin/customers",
    element: (
      <AdminRoute>
        <Customers />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/customers/new",
    element: (
      <AdminRoute>
        <EditCustomer />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/plans",
    element: (
      <AdminRoute>
        <Plans />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/plans/new",
    element: (
      <AdminRoute>
        <EditPlan />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/admins",
    element: (
      <AdminRoute>
        <Users />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/admins/new",
    element: (
      <AdminRoute>
        <EditAdmin />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/sales",
    element: (
      <AdminRoute>
        <Sales />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/arenas",
    element: (
      <AdminRoute>
        <Arenas />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/arenas/new",
    element: (
      <AdminRoute>
        <EditArena />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/arenas/edit/:id",
    element: (
      <AdminRoute>
        <EditArena />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/fields/new",
    element: (
      <AdminRoute>
        <EditField />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/fields/edit/:id",
    element: (
      <AdminRoute>
        <EditField />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/customers/edit/:id",
    element: (
      <AdminRoute>
        <EditCustomer />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/ads/new",
    element: (
      <AdminRoute>
        <EditAd />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/ads/edit/:id",
    element: (
      <AdminRoute>
        <EditAd />
      </AdminRoute>
    ),
  },
  {
    path: "/admin/settings",
    element: (
      <AdminRoute>
        <Settings />
      </AdminRoute>
    ),
  },
  {
    path: "/videos",
    element: (
      <ProtectedRoute>
        <Videos />
      </ProtectedRoute>
    ),
  },
  {
    path: "/videos/me",
    element: (
      <ProtectedRoute>
        <MyVideos />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user/profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: "/videos/plans",
    element: (
      <ProtectedRoute>
        <PlansList />
      </ProtectedRoute>
    ),
  },
  {
    path: "/video/:userId/:videoId/:purchaseId",
    element: <VideoPlayer />,
  },
  {
    path: "/purchase/:purchaseData",
    element: (
      <ProtectedRoute>
        <Purchase />
      </ProtectedRoute>
    ),
  },
  {
    path: "/401",
    element: <FourOhFOur />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);
