import { Card, List } from "antd/lib";
import React from "react";
import Meta from "antd/es/card/Meta";
import { Ads } from "../../../services/types";
import Loader from "../../loader";
import { useNavigate } from "react-router-dom";

type AdsListProps = {
  ads: Ads[];
  loading?: boolean;
};

const AdsList: React.FC<AdsListProps> = ({ ads, loading }) => {
  const navigate = useNavigate();
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 22,
          }}
        >
          <Loader />
        </div>
      ) : (
        <List
          grid={{
            gutter: 16,
            xs: 2,
            sm: 4,
            md: 6,
            lg: 6,
            xl: 6,
            xxl: 8,
          }}
          pagination={{ pageSize: 16 }}
          dataSource={ads}
          renderItem={(item: Ads) => (
            <List.Item>
              <Card
                cover={
                  <img
                    style={{ height: 130, objectFit: "contain" }}
                    alt="example"
                    src={item.img}
                  />
                }
                hoverable
                onClick={() => navigate(`/admin/ads/edit/${item.id}`)}
              >
                <Meta title={item.name} />
              </Card>
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default AdsList;
