import React from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form } from "antd";
import { Button, Input, Radio, Space, Upload } from "antd/lib";
import { InboxOutlined, PlaySquareFilled } from "@ant-design/icons";
import { RiMegaphoneFill } from "react-icons/ri";

const onFinish = (values: any) => {
  console.log("Received values of form: ", values);
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const Settings: React.FC = () => {
  return (
    <AppLayout title="Adicionar arena">
      <MainContent>
        <Form
          className={styles.form}
          name="complex-form"
          onFinish={onFinish}
          labelCol={{ span: 32 }}
          wrapperCol={{ span: 32 }}
          layout="vertical"
        >
          <Form.Item
            label={
              <>
                <RiMegaphoneFill style={{ marginRight: 8 }} /> Anúncio padrão
              </>
            }
            wrapperCol={{ span: 4 }}
          >
            <Form.Item
              name="dragger"
              valuePropName="defaultAd"
              getValueFromEvent={normFile}
            >
              <Upload.Dragger name="files" action="/upload.do">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Selecione ou arraste seu arquivo de imagem
                </p>
              </Upload.Dragger>
            </Form.Item>
            <Form.Item
              name="adDuration"
              label="Duração do anúncio"
              rules={[
                { required: true, message: "Duração do anúncio obrigatória" },
              ]}
            >
              <Input type="number" placeholder="5" addonAfter="Segundo(s)" />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <PlaySquareFilled style={{ marginRight: 8 }} /> Configurações de
                vídeo
              </>
            }
          >
            <Space wrap>
              <Form.Item
                name="previewStarts"
                label="Início do preview"
                rules={[
                  { required: true, message: "Início do preview obrigatório" },
                ]}
              >
                <Input type="number" placeholder="5" addonAfter="Segundo(s)" />
              </Form.Item>
              <Form.Item
                name="previewDuration"
                label="Duração do preview"
                rules={[
                  { required: true, message: "Duração do preview obrigatório" },
                ]}
              >
                <Input type="number" placeholder="5" addonAfter="Segundo(s)" />
              </Form.Item>
              <Form.Item
                name="fps"
                label="Taxa de frames por segundo"
                rules={[
                  {
                    required: true,
                    message: "Taxa de frames por segundo obrigatório",
                  },
                ]}
              >
                <Input type="number" placeholder="30" addonAfter="FPS" />
              </Form.Item>
              <Form.Item
                name="resolution"
                label="Resolução do vídeo"
                rules={[
                  {
                    required: true,
                    message: "Selecione uma Resolução do vídeo",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio.Button value="280p">280p (SD)</Radio.Button>
                  <Radio.Button value="360p">360p (SD)</Radio.Button>
                  <Radio.Button value="480p">480p (SD)</Radio.Button>
                  <Radio.Button value="720p">720p (HD)</Radio.Button>
                  <Radio.Button value="1080p">1080p (HD)</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="videoDuration"
                label="Duração do vídeo"
                rules={[
                  { required: true, message: "Duração do vídeo obrigatória" },
                ]}
              >
                <Input type="number" placeholder="5" addonAfter="Segundo(s)" />
              </Form.Item>
              <Form.Item
                name="price"
                label="Preço do vídeo avulso"
                rules={[
                  {
                    required: true,
                    message: "Preço do vídeo avulso obrigatório",
                  },
                ]}
              >
                <Input type="number" placeholder="0,00" addonBefore="R$" />
              </Form.Item>
              <Form.Item name="discount" label="Desconto do vídeo avulso">
                <Input type="number" placeholder="0" addonAfter="%" />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item label=" " noStyle colon={false}>
            <Button type="primary" htmlType="submit">
              Salvar configurações
            </Button>
          </Form.Item>
        </Form>
      </MainContent>
    </AppLayout>
  );
};

export default Settings;
