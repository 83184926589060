import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import Video from "../../components/video";
import { Alert, Button, ConfigProvider, List } from "antd/lib";
import { antdTheme } from "../../config/theme";
import { BellFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import VideoFilters from "../../components/videoFilters";
import Link from "antd/lib/typography/Link";
import { Video as VideoType } from "../../services/types";
import { getVideos } from "../../services/userFunctions";
import { GetVideosResponse } from "../../services/responses";
import Loader from "../../components/loader";
import { addToCart, getCartItems } from "../../auth/userService";
import UserHeader from "../../components/userHeader";
import locale from "antd/locale/pt_BR";
import { VideoFiltersPayload } from "../../services/payloads";
import useMessage from "antd/es/message/useMessage";

const Videos: React.FC = () => {
  const navigate = useNavigate();
  const [message, contextHolder] = useMessage();
  const [open, setOpen] = useState(false);
  const [videos, setVideos] = useState<VideoType[]>([]);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [pageSize, setPageSize] = useState(15);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  const getAllVideos = useCallback(async (filters?: VideoFiltersPayload) => {
    setLoadingVideos(true);
    const getAllVideosResponse = await getVideos(filters);
    if (!getAllVideosResponse.isError) {
      const res = (getAllVideosResponse as GetVideosResponse).data;
      setVideos(res);
    } else {
      // TODO tratar erro
    }
    setLoadingVideos(false);
  }, []);

  const handleAddToCart = (video: VideoType) => {
    if (getCartItems().length === 0) {
      setOpen(true);
    }
    addToCart(video, (alreadyAdded) =>
      alreadyAdded
        ? message.warning("Este item já foi adicionado!")
        : message.success("Adicionado ao carrinho!")
    );
    forceUpdate();
  };

  useEffect(() => {
    getAllVideos();
  }, [getAllVideos]);

  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      {contextHolder}
      <UserHeader
        openCartPopoverState={open}
        setOpenCartPopoverState={setOpen}
      />
      <section className={styles.filters}>
        <VideoFilters onFilter={(filters) => getAllVideos(filters)} />
      </section>
      <section className={styles.alertWrapper}>
        {!loadingVideos && (
          <Alert
            type="success"
            showIcon
            icon={<BellFilled />}
            message={
              <>
                Assine um plano e ganhe desconto na compra de vídeos.{" "}
                <Link className="mobile-link">Ver planos</Link>
              </>
            }
            action={<Button type="primary">Ver planos</Button>}
            onClick={() => navigate("/videos/plans")}
          />
        )}
      </section>
      <section className={styles.main}>
        {loadingVideos ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : (
          <List
            className={styles.videoList}
            pagination={{
              pageSize: pageSize,
              pageSizeOptions: [10, 15, 20, 50],
              onShowSizeChange: (current, size) => setPageSize(size),
            }}
            grid={{
              gutter: 16,
              xs: 1,
              sm: 1,
              md: 3,
              lg: 4,
              xl: 4,
              xxl: 5,
            }}
            dataSource={videos}
            renderItem={(video) => (
              <List.Item>
                <Video
                  video={video}
                  onAddToCard={() => handleAddToCart(video)}
                />
              </List.Item>
            )}
          />
        )}
      </section>
    </ConfigProvider>
  );
};

export default Videos;
