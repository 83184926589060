import React, { useCallback, useRef, useState } from "react";
import {
  SearchOutlined,
  CalendarFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  OrderedListOutlined,
  FilterFilled,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { Button, Card, DatePicker, Input, Space, Table, Tag } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { content } from "./content";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { SortOrder } from "antd/lib/table/interface";
import { Badge } from "antd/lib";
dayjs.extend(isBetween);

interface DataType {
  id: string;
  username: string;
  email: string;
  type: "Admin" | "Arena";
  createdAt: string;
}

type DataIndex = keyof DataType;

const data: DataType[] = content;

const AdminsTable: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [datesFilter, setDatesFilter] = useState<null | (dayjs.Dayjs | null)[]>(
    null
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onRangeChange = (
    dates: null | (dayjs.Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDatesFilter([dates[0], dates[1]]);
    } else {
      setDatesFilter(null);
    }
  };

  const getFilteredData = useCallback(() => {
    if (datesFilter !== null) {
      const allData = [...data];
      return allData.filter((souceItem) =>
        dayjs(souceItem.createdAt).isBetween(
          dayjs(datesFilter[0]).add(-1, "day"),
          dayjs(datesFilter[1]).add(1, "day")
        )
      );
    } else {
      return data;
    }
  }, [datesFilter]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Nome de usuário",
      dataIndex: "username",
      key: "username",
      ...getColumnSearchProps("username", "nome de usuário"),
      sorter: (a, b) => defaultSorter(a.username, b.username),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "e-mail"),
      sorter: (a, b) => defaultSorter(a.email, b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Perfil",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: "Admin", value: "Admin" },
        { text: "Arena", value: "Arena" },
      ],
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      onFilter: (value, record) => record.type.startsWith(value as string),
      sorter: (a, b) => defaultSorter(a.type, b.type),
      sortDirections: ["descend", "ascend"],
      render: (value) => (
        <Tag color={value === "Arena" ? "orange" : "blue"}>{value}</Tag>
      ),
    },
    {
      title: "Data de criação",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onRangeChange}
            value={datesFilter && [datesFilter[0], datesFilter[1]]}
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={datesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY"),
    },
  ];

  return (
    <Table
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
        filterReset: "Limpar",
        filterConfirm: "Filtrar",
      }}
      columns={columns}
      dataSource={getFilteredData()}
      scroll={{ x: 1000 }}
      bordered
      pagination={{ pageSize: 10 }}
    />
  );
};

export default AdminsTable;
