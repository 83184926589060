import { decrypt, encrypt } from "../services/crypto";
import { User, Video } from "../services/types";
import Cookies from "js-cookie";
const cookieName = String(process.env.REACT_APP_USER_COOKIE);
const cartCookieName = "cartitems";

export const saveUser = async (user: User) => {
  const encyptedUser = await encrypt(user);
  Cookies.set(cookieName, encyptedUser, { expires: 1 });
};

export const removeLoggedUser = async () => {
  clearCart();
  Cookies.remove(cookieName, { path: "/" });
};

export const getLoggedUser = () => {
  try {
    const userString = Cookies.get(cookieName);
    if (userString) {
      const userData = decrypt(userString);
      return userData;
    } else {
      return undefined;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getCartItems: () => Video[] = () => {
  const cartItemsString = Cookies.get(cartCookieName);
  if (cartItemsString) {
    return JSON.parse(cartItemsString);
  } else {
    return [];
  }
};

export const removeCartItem: (video: Video) => void = (video) => {
  const cookieCartItems = getCartItems();

  const removedItem = cookieCartItems.filter((item) => item.id !== video.id);

  Cookies.set(cartCookieName, JSON.stringify(removedItem));
};

export const clearCart: () => void = () => {
  Cookies.set(cartCookieName, JSON.stringify([]));
};

export const addToCart: (
  video: Video,
  callback?: (alreadyAdded: boolean) => void
) => void = (video: Video, callback) => {
  const cookieCartItems = getCartItems();
  let allCartItems: Video[] = [];
  let alreadyAdded = false;

  if (cookieCartItems.some((item) => item.id === video.id)) {
    allCartItems = [...cookieCartItems];
    alreadyAdded = true;
  } else {
    allCartItems = [...cookieCartItems, video];
  }

  Cookies.set(cartCookieName, JSON.stringify(allCartItems));
  callback && callback(alreadyAdded);
};
