import { useNavigate, useParams } from "react-router-dom";
import { initMercadoPago, Payment, StatusScreen } from "@mercadopago/sdk-react";
import { useEffect, useState } from "react";
import { decryptPurchaseData } from "../../services/crypto";
import { PurchaseData } from "../../services/types";
import PurchaseSumary from "../../components/purchaseSumary";
import styles from "./styles.module.css";
import { finishPurchase } from "../../services/userFunctions";
import { FinishPurchaseResponse } from "../../services/responses";
import { IPaymentFormData } from "@mercadopago/sdk-react/bricks/payment/type";
import useMessage from "antd/es/message/useMessage";
import { clearCart } from "../../auth/userService";
import { Button } from "antd";
const Purchase = () => {
  let { purchaseData } = useParams();
  const navigate = useNavigate();
  const data: PurchaseData = decryptPurchaseData(
    String(purchaseData)
  ) as PurchaseData;
  const [finishPurchaseData, setFinishPurchaseData] = useState<
    FinishPurchaseResponse | undefined
  >(undefined);
  console.log(data);
  const [message, contextHolder] = useMessage();

  useEffect(() => {
    initMercadoPago(String(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY), {
      locale: "pt-BR",
    });
  }, [purchaseData]);

  const onFinish = async (purchase: IPaymentFormData) => {
    const purchaseResponse = await finishPurchase({
      userId: data.userId,
      preferenceId: data.preferenceId,
      externalReference: data.externalReference,
      ...purchase,
    });
    if (!purchaseResponse.isError) {
      clearCart();
      setFinishPurchaseData(purchaseResponse as FinishPurchaseResponse);
    } else {
      message.error(
        "Ocorreu um erro ao processar sua compra. Verifique sua conexão ou tente novamente"
      );
    }
  };

  return (
    <>
      {contextHolder}
      {finishPurchaseData ? (
        <div className={styles.statusWrapper}>
          <StatusScreen
            initialization={{ paymentId: finishPurchaseData.id }}
            customization={{
              visual: {
                style: {
                  theme: "default",
                  customVariables: {
                    baseColor: "var(--primary)",
                  },
                },
              },
            }}
          />
          <Button
            size="large"
            onClick={() => navigate("/videos/me")}
            type="primary"
          >
            Ver meus vídeos
          </Button>
        </div>
      ) : (
        <div className={styles.purchaseWrapper}>
          <Payment
            locale="pt-br"
            key={data.preferenceId}
            customization={{
              enableReviewStep: true,

              visual: {
                style: {
                  theme: "default",
                  customVariables: {
                    baseColor: "var(--primary)",
                  },
                },
              },
              paymentMethods: {
                creditCard: "all",
                bankTransfer: "all",
                maxInstallments: 1,
              },
            }}
            initialization={{
              preferenceId: data.preferenceId,
              items: {
                itemsList: data.cartItems.map((i) => ({
                  name: i.name,
                  units: 1,
                  value: data.price,
                  description: i.arenaName,
                })),
                totalItemsAmount: data.finalPrice,
              },

              amount: data.finalPrice,
              payer: {
                firstName: data.payer.name,
                email: data.payer.email,
              },
            }}
            onSubmit={onFinish}
          />
          <PurchaseSumary purchase={data} />
        </div>
      )}
    </>
  );
};
export default Purchase;
