import React, { useState } from "react";

import styles from "./styles.module.css";
import { Button, Card, Form, FormProps, Input } from "antd";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Alert from "antd/lib/alert/Alert";
import { loginUser } from "../../services/userFunctions";
import { LoginPayload } from "../../services/payloads";
import useMessage from "antd/lib/message/useMessage";
import { APIErrorResponse } from "../../services/responses";
import { getLoggedUser, saveUser } from "../../auth/userService";
import { User } from "../../services/types";

const onFinishFailed: FormProps<LoginPayload>["onFinishFailed"] = (
  errorInfo
) => {
  console.log("Failed:", errorInfo);
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const session = getLoggedUser();

  const [searchParams] = useSearchParams();
  const newUSer = searchParams.get("newUser");
  const [message, contextHolder] = useMessage();

  const [loading, setLoading] = useState(false);

  const onFinish: FormProps<LoginPayload>["onFinish"] = async (values) => {
    setLoading(true);

    const loginResponse = await loginUser(values);
    if (!loginResponse.isError) {
      const userData = loginResponse as User;
      await saveUser(userData);
      navigate("/videos");
    } else {
      message.error((loginResponse as APIErrorResponse).errorMessage);
    }

    setLoading(false);
  };

  if (session && typeof session !== "string") {
    return <Navigate to="/videos" replace />;
  }

  return (
    <div className={styles.main}>
      {contextHolder}
      <div className={styles.background}></div>
      <main className={styles.loginContainer}>
        <Card className={styles.card}>
          <img src="/logo-full.png" alt="logo" className={styles.logo} />
          {newUSer && (
            <Alert
              message="Usuário criado com sucesso! Agora você pode entrar com sua conta"
              type="success"
              showIcon
              style={{ marginBottom: 22 }}
            />
          )}
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item<LoginPayload>
              label="Email"
              name="email"
              rules={[{ required: true, message: "Insira seu e-mail!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<LoginPayload>
              label="Senha"
              name="password"
              rules={[{ required: true, message: "Insira sua senha!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Login
              </Button>
            </Form.Item>

            <Button block type="link" href="/recover-password">
              Esqueci minha senha
            </Button>

            <Button block type="link" href="/register">
              Ainda não tem conta? <strong>Cadastre-se</strong>
            </Button>
          </Form>
        </Card>
      </main>
    </div>
  );
};

export default Login;
