import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Alert, Form, Result } from "antd";
import {
  Avatar,
  Button,
  Drawer,
  Input,
  List,
  Radio,
  Space,
  Tooltip,
} from "antd/lib";
import { TbSoccerField } from "react-icons/tb";
import { ClockCircleFilled, SwapOutlined } from "@ant-design/icons";
import { IoMdFootball } from "react-icons/io";
import ArenasTable from "../../components/tables/arenasTable";
import { Arena, Field } from "../../services/types";
import {
  createField,
  getArenas,
  getFieldById,
} from "../../services/adminFunctions";
import { GetArenasResponse } from "../../services/responses";
import { CreateFieldPayload } from "../../services/payloads";
import useMessage from "antd/lib/message/useMessage";
import { getLoggedUser } from "../../auth/userService";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";

const EditField: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [message, contextHolder] = useMessage();

  const [open, setOpen] = useState(false);
  const [arenas, setArenas] = useState<Arena[]>([]);
  const [loadingArenas, setLoadingArenas] = useState(false);
  const [selectedArena, setSelectedArena] = useState<Arena | undefined>(
    undefined
  );
  const [updatingField, setUpdatingField] = useState(false);
  const session = getLoggedUser();
  const [fieldError, setFieldError] = useState(false);
  const [loadingField, setLoadingField] = useState(false);

  let ADMIN_ID = "e8e39acf-dd44-47dd-bb9b-bae8516f4433";

  if (session && typeof session !== "string") {
    ADMIN_ID = session.id;
  }

  const onFinish = async (values: any) => {
    setUpdatingField(true);
    const payload: CreateFieldPayload = {
      ...values,
      fieldId: id,
      arenaId: selectedArena?.id,
      userId: ADMIN_ID,
      toleranceWeekday: Number(values.toleranceWeekday),
      toleranceWeekend: Number(values.toleranceWeekend),
    };
    const createArenaResponse = await createField(payload);
    if (!createArenaResponse.isError) {
      message.success("Campo atualizado com sucesso!");
      navigate("/admin/arenas");
    } else {
      message.error(
        "Erro ao atualizar o Campo! Verifique sua conexão ou tente novamente mais tarde."
      );
    }

    setUpdatingField(false);
  };

  const getField = useCallback(async () => {
    setFieldError(false);
    setLoadingArenas(true);
    const getAllArenasResponse = await getArenas();
    if (!getAllArenasResponse.isError) {
      const res = (getAllArenasResponse as GetArenasResponse).data;
      setArenas(res);
    } else {
      setFieldError(true);
      return;
    }
    setLoadingArenas(false);
    if (id) {
      setLoadingField(true);
      const getFieldResponse = await getFieldById(String(id));
      if (!getFieldResponse.isError) {
        form.setFieldsValue(getFieldResponse);
        if ((getFieldResponse as Field).arenaId) {
          setSelectedArena(
            (getAllArenasResponse as GetArenasResponse).data.find(
              (arena) => arena.id === (getFieldResponse as Field).arenaId
            )
          );
        }
      } else {
        setFieldError(true);
      }
      setLoadingField(false);
    }
  }, [id, form]);

  useEffect(() => {
    getField();
  }, [getField]);
  return (
    <AppLayout title="Adicionar campo">
      <MainContent>
        <>
          {contextHolder}
          <Drawer
            classNames={{ wrapper: styles.arenasDrawerContent }}
            title="Selecione uma Arena"
            placement="bottom"
            onClose={() => setOpen(false)}
            open={open}
          >
            <ArenasTable
              arenas={arenas}
              loading={loadingArenas}
              onRowClick={(arena) => {
                setSelectedArena(arena);
                setOpen(false);
              }}
            />
          </Drawer>
          {loadingField ? (
            <Loader />
          ) : fieldError ? (
            <Result
              status="error"
              title="Falha ao obter dados do Campo"
              subTitle="Por favor, verifique sua conexão com a internet e tente novamente."
              extra={[
                <Button type="primary" key="console" onClick={() => getField()}>
                  Tentar novamente
                </Button>,
                <Button key="buy" onClick={() => navigate(-1)}>
                  Voltar
                </Button>,
              ]}
            />
          ) : (
            <Form
              form={form}
              className={styles.form}
              name="complex-form"
              onFinish={onFinish}
              labelCol={{ span: 32 }}
              wrapperCol={{ span: 32 }}
              layout="vertical"
            >
              <Form.Item
                label={
                  <>
                    <TbSoccerField style={{ marginRight: 8 }} /> Dados do campo
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name="description"
                    label="Nome"
                    rules={[
                      { required: true, message: "Nome do campo obrigatório" },
                    ]}
                  >
                    <Input placeholder="Nome do campo" />
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <ClockCircleFilled style={{ marginRight: 8 }} /> Horários
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name="sunday"
                    label="Domingo"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="monday"
                    label="Segunda-feira"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="tuesday"
                    label="Terça-feira"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="wednesday"
                    label="Quarta-feira"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="thursday"
                    label="Quinta-feira"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="friday"
                    label="Sexta-feira"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="saturday"
                    label="Sábado"
                    rules={[
                      { required: true, message: "Selecione um horário" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio.Button value="MINUTO_00">Minuto 00</Radio.Button>
                      <Radio.Button value="MINUTO_30">Minuto 30</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="toleranceWeekday"
                    label="Tolerância durante a semana"
                    rules={[
                      {
                        required: true,
                        message: "Tempo de tolerância obrigatório",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="0"
                      addonAfter="Minuto(s)"
                      max={60}
                    />
                  </Form.Item>
                  <Form.Item
                    name="toleranceWeekend"
                    label="Tolerância durante o fim de semana"
                    rules={[
                      {
                        required: true,
                        message: "Tempo de tolerância obrigatório",
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="0"
                      addonAfter="Minuto(s)"
                      max={60}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <IoMdFootball style={{ marginRight: 8 }} /> Arena
                    pertencente
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name="arena"
                    label=""
                    rules={[
                      {
                        required: selectedArena === undefined,
                        message: "Selecione uma arena",
                      },
                    ]}
                  >
                    {/* <Input placeholder="Nome do campo" /> */}
                    {selectedArena ? (
                      <List
                        className={styles.arenaItem}
                        dataSource={["1"]}
                        renderItem={() => (
                          <List.Item
                            onClick={() => setOpen(true)}
                            actions={[
                              <Tooltip title="Alterar Arena">
                                <Button
                                  type="primary"
                                  icon={<SwapOutlined />}
                                />
                                ,
                              </Tooltip>,
                            ]}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  alt="arena logo"
                                  src={selectedArena.logoImg}
                                />
                              }
                              title={selectedArena.name}
                              description="Arena selecionada"
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <Alert
                        banner
                        action={
                          <Button
                            type="primary"
                            style={{ marginLeft: 12 }}
                            onClick={() => setOpen(true)}
                          >
                            Selecionar Arena
                          </Button>
                        }
                        type="error"
                        showIcon
                        message="Nenhuma arena selecionada"
                      />
                    )}
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item label=" " noStyle colon={false}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={updatingField}
                >
                  Salvar campo
                </Button>
              </Form.Item>
            </Form>
          )}
        </>
      </MainContent>
    </AppLayout>
  );
};

export default EditField;
