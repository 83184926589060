import React, { useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form, Tooltip, Typography } from "antd";
import { Button, FormProps, Input, Radio, Space } from "antd/lib";
import { InfoCircleFilled, TagFilled } from "@ant-design/icons";
import { colors } from "../../config/theme";
import { CreatePlanPayload } from "../../services/payloads";
import useMessage from "antd/lib/message/useMessage";
import { createPlan } from "../../services/adminFunctions";
import PlanCard from "../../components/planCard";
import { Plan } from "../../services/types";
import { getLoggedUser } from "../../auth/userService";

const EditPlan: React.FC = () => {
  const [message, contextHolder] = useMessage();
  const [loadingCreatePlan, setLoadingCreatePlan] = useState(false);
  const [planPreview, setPlanPreview] = useState<Plan>({
    createdAt: "",
    description: "",
    frequency: "MENSAL",
    id: "...",
    isActive: true,
    name: "Visualização do plano",
    price: 0,
    priceDescription: "Descrição do plano",
    videosQuantity: 0,
    updatedAt: "",
  });
  const session = getLoggedUser();

  let ADMIN_ID = "e8e39acf-dd44-47dd-bb9b-bae8516f4433";

  if (session && typeof session !== "string") {
    ADMIN_ID = session.id;
  }

  const onChangeForm: FormProps<CreatePlanPayload>["onFinish"] = async (
    values: any
  ) => {
    setPlanPreview({ ...planPreview, ...values });
  };

  const onFinish: FormProps<CreatePlanPayload>["onFinish"] = async (
    values: any
  ) => {
    setLoadingCreatePlan(true);
    const payload: CreatePlanPayload = {
      ...values,

      userId: ADMIN_ID,
    };
    const createPlanResponse = await createPlan(payload);
    if (!createPlanResponse.isError) {
      message.success("Plano adicionado com sucesso!");
    } else {
      message.error(
        "Ocorreu um erro ao adicionar o plano. Verifique sua conexão com a internet ou tente novamente mais tarde."
      );
    }
    setLoadingCreatePlan(false);
  };

  return (
    <AppLayout title="Adicionar plano">
      <MainContent>
        <div className={styles.wrapper}>
          {contextHolder}

          <Form
            className={styles.form}
            name="complex-form"
            onFinish={onFinish}
            onValuesChange={onChangeForm}
            labelCol={{ span: 32 }}
            wrapperCol={{ span: 32 }}
            layout="vertical"
          >
            <Form.Item
              label={
                <>
                  <TagFilled style={{ marginRight: 8 }} /> Dados do plano
                </>
              }
            >
              <Space wrap>
                {/* <Form.Item<CreatePlanPayload>
                name="status"
                label="Status do plano"
                rules={[{ required: true, message: "Selecione um status" }]}
              >
                <Radio.Group>
                  <Radio.Button value="Active">Ativo</Radio.Button>
                  <Radio.Button value="Inactive">Inativo</Radio.Button>
                </Radio.Group>
              </Form.Item> */}
                <Form.Item<CreatePlanPayload>
                  name="name"
                  label="Nome do plano"
                  rules={[{ required: true, message: "Nome obrigatório" }]}
                >
                  <Input placeholder="Nome do plano" maxLength={256} />
                </Form.Item>
                <Form.Item<CreatePlanPayload>
                  name="frequency"
                  label="Frequência de cobrança"
                  rules={[
                    {
                      required: true,
                      message: "Selecione uma frequência de cobrança",
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio.Button value="ANUAL">Anual</Radio.Button>
                    <Radio.Button value="MENSAL">Mensal</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {/* <Form.Item<CreatePlanPayload>
                name="expirationType"
                label="Expiração do plano"
                rules={[
                  { required: true, message: "Selecione um tipo de expiração" },
                ]}
              >
                <Radio.Group>
                  <Radio value="NonExpires">
                    Não expira (Apenas se o usuário cancelar)
                  </Radio>
                  <Radio value="ExpiresAt">Expira em: </Radio>
                  <DatePicker />
                </Radio.Group>
              </Form.Item> */}
                <Form.Item<CreatePlanPayload>
                  name="price"
                  label="Preço"
                  rules={[{ required: true, message: "Preço obrigatório" }]}
                >
                  <Input type="number" placeholder="0,00" addonBefore="R$" />
                </Form.Item>
                {/* <Form.Item<CreatePlanPayload>
                name="limit"
                label="Quantidade máxima de adesões"
                rules={[
                  {
                    required: true,
                    message: "Quantidade máxima de adesões obrigatório",
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Quantidade máxima de adesões"
                />
              </Form.Item> */}
                {/* <Form.Item<CreatePlanPayload>
                name="trialSize"
                label={
                  <>
                    Período de testes (Dias)
                    <Tooltip title="Dias de período de teste (sem gerar cobrança). Máximo de 100 dias">
                      <InfoCircleFilled
                        style={{ color: colors.warningColor, marginLeft: 8 }}
                      />
                    </Tooltip>
                  </>
                }
              >
                <Input
                  type="number"
                  placeholder="Período de testes"
                  addonAfter="Dia(s)"
                />
              </Form.Item> */}
                <Form.Item<CreatePlanPayload>
                  name="videosQuantity"
                  label={
                    <>
                      Quantidade de vídeos
                      <Tooltip title="Quantidade de vídeos que podem ser baixados. Deixe em branco para ILIMITADO">
                        <InfoCircleFilled
                          style={{ color: colors.warningColor, marginLeft: 8 }}
                        />
                      </Tooltip>
                    </>
                  }
                >
                  <Input
                    type="number"
                    placeholder="Quantidade de vídeos"
                    addonAfter="Vídeo(s)"
                  />
                </Form.Item>
              </Space>
              <Form.Item<CreatePlanPayload>
                name="description"
                label="Título da descrição do plano"
                wrapperCol={{ span: 8 }}
                rules={[
                  {
                    required: true,
                    message: "Título da descrição do plano obrigatória",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Título da descrição do plano"
                  maxLength={256}
                />
              </Form.Item>
              <Form.Item<CreatePlanPayload>
                name="priceDescription"
                label="Descrição do plano"
                wrapperCol={{ span: 8 }}
                rules={[
                  { required: true, message: "Descrição do plano obrigatória" },
                ]}
              >
                <Input.TextArea
                  placeholder="Descrição do plano"
                  maxLength={256}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item label=" " noStyle colon={false}>
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingCreatePlan}
              >
                Salvar plano
              </Button>
            </Form.Item>
          </Form>
          <span>
            <Typography.Title level={5}>Pré-visualizar Plano:</Typography.Title>
            <PlanCard plan={planPreview} />
          </span>
        </div>
      </MainContent>
    </AppLayout>
  );
};

export default EditPlan;
