import React, { useRef, useState } from "react";
import {
  SearchOutlined,
  SortDescendingOutlined,
  SortAscendingOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import { Button, Input, Space, Table } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { Badge } from "antd/lib";
import { SortOrder } from "antd/lib/table/interface";
import { User } from "../../../services/types";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader";
import styles from "./styles.module.css";

type DataIndex = keyof User;

type CustomersTableProps = {
  users: User[];
  loading: boolean;
  onRowClick?: (rowContent: User) => void;
};

const CustomerTable: React.FC<CustomersTableProps> = ({
  loading,
  users,
  onRowClick,
}) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<User> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<User> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name", "nome"),
      sorter: (a, b) => defaultSorter(a.name, b.name),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email", "e-mail"),
      sorter: (a, b) => defaultSorter(a.email, b.email),
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Table
      className={styles.arenaTable}
      onRow={(r) => ({
        onClick: () =>
          onRowClick
            ? onRowClick(r)
            : navigate(`/admin/customers/edit/${r.id}`),
      })}
      loading={{
        spinning: loading,
        indicator: (
          <div>
            <Loader />
          </div>
        ),
      }}
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
      }}
      columns={columns}
      dataSource={users}
      scroll={{ x: 600 }}
      bordered
      pagination={{ pageSize: 10 }}
    />
  );
};

export default CustomerTable;
