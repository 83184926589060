import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form, Image } from "antd";
import { Button, Input, Result, Space, Upload } from "antd/lib";
import { DeleteFilled, InboxOutlined, PictureFilled } from "@ant-design/icons";
import { RiMegaphoneFill } from "react-icons/ri";
import { getLoggedUser } from "../../auth/userService";
import { APIErrorResponse } from "../../services/responses";
import {
  getAdById,
  updateAd,
  uploadImage,
} from "../../services/adminFunctions";
import useMessage from "antd/lib/message/useMessage";
import { rcFileToBase64 } from "../../utils/imageHelper";
import { UpdateAdsPayload } from "../../services/payloads";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { Ads } from "../../services/types";

const EditAd: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, contextHolder] = useMessage();
  const [adsImage, setAdsImage] = useState<string | undefined>(undefined);
  const [adError, setAdError] = useState(false);
  const [loadingAd, setLoadingAd] = useState(false);
  const session = getLoggedUser();

  const normFile = async (e: any) => {
    const base64File = await rcFileToBase64(e);
    setAdsImage(base64File);
    if (Array.isArray(e)) {
      return e;
    }

    return e?.file;
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const needsUpload = adsImage && !adsImage.startsWith("https://");
    const imageUrl = !needsUpload
      ? adsImage
      : await uploadImage({ image: String(adsImage) });
    if (needsUpload && (imageUrl as APIErrorResponse).isError) {
      message.error(
        "Erro ao enviar imagem! Verifique sua conexão ou tente novamente mais tarde."
      );
      return;
    }

    delete values.dragger;
    delete values.email;
    const payload: UpdateAdsPayload = {
      ...values,
      userId: session && typeof session !== "string" ? session.id : "",
      img: imageUrl,
      adsId: id,
    };
    const createAdResponse = await updateAd(payload);
    console.log(createAdResponse);
    if (!createAdResponse.isError) {
      message.success("Anúncio atualizado com sucesso!");
      navigate("/admin/arenas");
    } else {
      message.error(
        "Erro ao atualizar anúncio! Verifique sua conexão ou tente novamente mais tarde."
      );
    }
    setLoading(false);
  };

  const getAd = useCallback(async () => {
    setAdError(false);
    if (id) {
      setLoadingAd(true);
      const getAdResponse = await getAdById(String(id));
      if (!getAdResponse.isError) {
        form.setFieldsValue(getAdResponse);
        setAdsImage((getAdResponse as Ads).img);
      } else {
        setAdError(true);
      }
      setLoadingAd(false);
    }
  }, [id, form]);

  useEffect(() => {
    getAd();
  }, [getAd]);

  return (
    <>
      {contextHolder}

      <AppLayout title="Adicionar anúncio">
        <MainContent>
          {loadingAd ? (
            <Loader />
          ) : adError ? (
            <Result
              status="error"
              title="Falha ao obter dados do anúncio"
              subTitle="Por favor, verifique sua conexão com a internet e tente novamente."
              extra={[
                <Button type="primary" key="console" onClick={() => getAd()}>
                  Tentar novamente
                </Button>,
                <Button key="buy" onClick={() => navigate(-1)}>
                  Voltar
                </Button>,
              ]}
            />
          ) : (
            <Form
              form={form}
              className={styles.form}
              name="complex-form"
              onFinish={onFinish}
              labelCol={{ span: 32 }}
              wrapperCol={{ span: 32 }}
              layout="vertical"
            >
              <Form.Item
                label={
                  <>
                    <PictureFilled style={{ marginRight: 8 }} /> Imagem do
                    anúncio
                  </>
                }
                wrapperCol={{ span: 4 }}
              >
                <Form.Item
                  name="dragger"
                  valuePropName="imageFile"
                  noStyle
                  rules={[
                    {
                      required: adsImage === undefined,
                      message: "Escolha um logo para o anúncio!",
                    },
                  ]}
                >
                  {adsImage ? (
                    <div className={styles.logoWrapper}>
                      <Image width={150} src={adsImage} preview={false} />
                      <Button
                        danger
                        type="primary"
                        onClick={() => setAdsImage(undefined)}
                        icon={<DeleteFilled />}
                      >
                        Remover?
                      </Button>
                    </div>
                  ) : (
                    <Upload.Dragger
                      name="dragger"
                      multiple={false}
                      accept="image/*"
                      action={normFile}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Selecione ou arraste seu arquivo de imagem
                      </p>
                    </Upload.Dragger>
                  )}
                </Form.Item>
              </Form.Item>
              <Form.Item
                label={
                  <>
                    <RiMegaphoneFill style={{ marginRight: 8 }} /> Dados do
                    anúncio
                  </>
                }
              >
                <Space wrap>
                  <Form.Item
                    name="name"
                    label="Título do anúncio"
                    rules={[
                      {
                        required: true,
                        message: "Título do anúncio obrigatório",
                      },
                    ]}
                  >
                    <Input placeholder="Título do anúncio" />
                  </Form.Item>
                </Space>
              </Form.Item>

              <Form.Item label=" " noStyle colon={false}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Salvar anúncio
                </Button>
              </Form.Item>
            </Form>
          )}
        </MainContent>
      </AppLayout>
    </>
  );
};

export default EditAd;
