import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { ConfigProvider } from "antd/lib";
import { antdTheme } from "../../config/theme";
// import { useNavigate } from "react-router-dom";
import locale from "antd/locale/pt_BR";
// import { getLoggedUser } from "../../auth/userService";
import UserHeader from "../../components/userHeader";

import { Typography } from "antd";
import { Plan } from "../../services/types";
import { getPlans } from "../../services/adminFunctions";
import { GetPlansResponse } from "../../services/responses";
import Loader from "../../components/loader";
import PlanCard from "../../components/planCard";

const PlansList: React.FC = () => {
  // const navigate = useNavigate();
  // console.log(getLoggedUser());
  const [openCart, setOpenCart] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loadingPlans, setLoadingPlans] = useState(false);

  const getAllPlans = useCallback(async () => {
    setLoadingPlans(true);
    const getPlansRespose = await getPlans();
    if (!getPlansRespose.isError) {
      const res = (getPlansRespose as GetPlansResponse).data;
      setPlans(res);
    } else {
      // TODO tratar erro
    }
    setLoadingPlans(false);
  }, []);

  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  return (
    <ConfigProvider theme={antdTheme} locale={locale}>
      <UserHeader
        openCartPopoverState={openCart}
        setOpenCartPopoverState={setOpenCart}
      />

      <section className={styles.main}>
        <div className={styles.banner}>
          <Typography.Title className="title">
            Planos de Assinatura
          </Typography.Title>
          <Typography.Text className="subtitle">
            Escolha o plano que melhor se encaixa ao seu perfil e economize na
            compra de vídeos
          </Typography.Text>
        </div>
        <div className={styles.plansWrapper}>
          {loadingPlans ? (
            <Loader></Loader>
          ) : (
            plans.map((plan) => <PlanCard plan={plan} />)
          )}
        </div>
      </section>
    </ConfigProvider>
  );
};

export default PlansList;
