import React, { useState } from "react";

import styles from "./styles.module.css";
import { Button, Card, Form, FormProps, Input } from "antd";
import { RegisterUserPayload } from "../../services/payloads";
import { registerUser } from "../../services/userFunctions";
import useMessage from "antd/lib/message/useMessage";
import { useNavigate } from "react-router-dom";

const onFinishFailed: FormProps<RegisterUserPayload>["onFinishFailed"] = (
  errorInfo
) => {
  console.log("Failed:", errorInfo);
};

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [message, contextHolder] = useMessage();

  const [loading, setLoading] = useState(false);

  const onFinish: FormProps<RegisterUserPayload>["onFinish"] = async (
    values
  ) => {
    console.log("Success:", values);
    setLoading(true);
    console.log("Received values of form: ", values);

    const createArenaResponse = await registerUser(values);
    if (!createArenaResponse.isError) {
      message.success("Usuário cadastrado! Redirecionando...");
      navigate("/?newUser=true");
    } else {
      message.error(
        "Erro ao cadastrar usuário! Verifique sua conexão ou tente novamente mais tarde."
      );
    }

    setLoading(false);
  };

  return (
    <div className={styles.main}>
      {contextHolder}
      <div className={styles.background}></div>
      <main className={styles.loginContainer}>
        <Card className={styles.card}>
          <img src="/logo-full.png" alt="logo" className={styles.logo} />
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item<RegisterUserPayload>
              label="Nome completo"
              name="name"
              rules={[
                { required: true, message: "Insira seu nome completo!" },
                {
                  min: 4,
                  message: "Insira seu nome completo!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item<RegisterUserPayload>
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Insira seu e-mail!" },
                {
                  pattern: new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                  message: "E-mail inválido!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item<RegisterUserPayload>
              label="Senha"
              name="password"
              rules={[{ required: true, message: "Insira sua senha!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button block type="primary" htmlType="submit" loading={loading}>
                Cadastrar
              </Button>
            </Form.Item>

            <Button block type="link" href="/">
              Já possui conta? <strong>Login</strong>
            </Button>
          </Form>
        </Card>
      </main>
    </div>
  );
};

export default Register;
