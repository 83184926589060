import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Form } from "antd";
import { Button, Input, Result, Select, Space } from "antd/lib";
import { State, City, IState, ICity } from "country-state-city";
import { EnvironmentFilled, IdcardFilled } from "@ant-design/icons";
import { UpdateUserPayload } from "../../services/payloads";
import { getUserById, updateUser } from "../../services/adminFunctions";
import useMessage from "antd/lib/message/useMessage";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import { MaskedInput } from "antd-mask-input";
import { getLoggedUser } from "../../auth/userService";

const EditCustomer: React.FC = () => {
  const [message, contextHolder] = useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [stateList, setStateList] = useState<IState[]>([]);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [updatingUser, setUpdatingUser] = useState(false);

  const [userError, setUserError] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);

  const session = getLoggedUser();

  let ADMIN_ID = "e8e39acf-dd44-47dd-bb9b-bae8516f4433";

  if (session && typeof session !== "string") {
    ADMIN_ID = session.id;
  }

  const onFinish = async (values: UpdateUserPayload) => {
    setUpdatingUser(true);
    const payload: UpdateUserPayload = {
      ...values,
      userId: id,
      adminId: ADMIN_ID,
    };
    const createArenaResponse = await updateUser(payload);
    if (!createArenaResponse.isError) {
      message.success("Cliente atualizado com sucesso!");
    } else {
      message.error(
        "Erro ao atualizar o cliente! Verifique sua conexão ou tente novamente mais tarde."
      );
    }

    setUpdatingUser(false);
  };

  const getAllStates = useCallback(() => {
    setStateList(State.getStatesOfCountry("BR"));
  }, []);

  const getCities = useCallback((stateCode: string) => {
    setCityList(City.getCitiesOfState("BR", stateCode));
  }, []);

  const getUser = useCallback(async () => {
    setUserError(false);
    if (id) {
      setLoadingUser(true);
      const getUserResponse = await getUserById(id);
      if (!getUserResponse.isError) {
        form.setFieldsValue(getUserResponse);
      } else {
        setUserError(true);
      }
      setLoadingUser(false);
    }
  }, [id, form]);

  useEffect(() => {
    getUser();
    getAllStates();
  }, [getAllStates, getUser]);

  return (
    <AppLayout title="Adicionar cliente">
      <>
        {contextHolder}
        <MainContent>
          {loadingUser ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <>
              {userError ? (
                <Result
                  status="error"
                  title="Falha ao obter dados do cliente"
                  subTitle="Por favor, verifique sua conexão com a internet e tente novamente."
                  extra={[
                    <Button
                      type="primary"
                      key="console"
                      onClick={() => getUser()}
                    >
                      Tentar novamente
                    </Button>,
                    <Button key="buy" onClick={() => navigate(-1)}>
                      Voltar
                    </Button>,
                  ]}
                />
              ) : (
                <Form
                  form={form}
                  className={styles.form}
                  name="complex-form"
                  onFinish={onFinish}
                  labelCol={{ span: 32 }}
                  wrapperCol={{ span: 32 }}
                  layout="vertical"
                >
                  <Form.Item
                    label={
                      <>
                        <IdcardFilled style={{ marginRight: 8 }} /> Dados do
                        usuário
                      </>
                    }
                  >
                    <Space wrap>
                      <Form.Item
                        name="name"
                        label="Nome completo"
                        rules={[
                          {
                            required: true,
                            message: "Nome completo obrigatório",
                          },
                        ]}
                      >
                        <Input placeholder="Nome completo" />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="E-mail"
                        auto-complete="off"
                        rules={[
                          { required: true, message: "E-mail obrigatório" },
                        ]}
                      >
                        <Input placeholder="E-mail" autoComplete="new-user" />
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        label="Celular"
                        rules={[
                          {
                            required: true,
                            message: "Número de celular obrigatório",
                          },
                          {
                            pattern: new RegExp(/^\d{2} \d \d{4}-\d{4}$/),
                            message: "Número de celular inválido!",
                          },
                        ]}
                      >
                        <MaskedInput
                          mask={"00 0 0000-0000"}
                          placeholder="99 9 9999-9999"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Senha"
                        auto-complete="off"
                      >
                        <Input.Password
                          placeholder="Senha"
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        <EnvironmentFilled style={{ marginRight: 8 }} />{" "}
                        Endereço
                      </>
                    }
                  >
                    <Space wrap>
                      <Form.Item
                        name={["address", "state"]}
                        label="Estado"
                        rules={[
                          { required: true, message: "Selecione um estado" },
                        ]}
                      >
                        <Select
                          placeholder="Estado"
                          showSearch
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          autoComplete="new-state"
                          options={stateList.map((c) => ({
                            label: c.name,
                            value: c.isoCode,
                          }))}
                          onChange={(option) => getCities(option)}
                        ></Select>
                      </Form.Item>
                      <Form.Item
                        name={["address", "city"]}
                        label="Cidade"
                        rules={[
                          { required: true, message: "Selecione a cidade" },
                        ]}
                      >
                        <Select
                          placeholder="Cidade"
                          showSearch
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          autoComplete="new-state"
                          options={cityList.map((c) => ({
                            label: c.name,
                            value: c.name,
                          }))}
                        ></Select>
                      </Form.Item>
                      <Form.Item
                        name={["address", "zip"]}
                        label="CEP"
                        rules={[
                          { required: true, message: "CEP obrigatório" },
                          {
                            pattern: new RegExp(/^\d{5}-\d{3}$/),
                            message: "CEP inválido!",
                          },
                        ]}
                      >
                        <MaskedInput
                          mask={"00000-000"}
                          placeholder="00000-000"
                        />
                      </Form.Item>
                      <Form.Item
                        name={["address", "street"]}
                        label="Logradouro"
                        rules={[
                          { required: true, message: "Logradouro obrigatório" },
                        ]}
                      >
                        <Input placeholder="Logradouro" maxLength={256} />
                      </Form.Item>
                      <Form.Item
                        name={["address", "district"]}
                        label="Bairro"
                        rules={[
                          { required: true, message: "Bairro obrigatório" },
                        ]}
                      >
                        <Input placeholder="Bairro" maxLength={256} />
                      </Form.Item>
                      <Form.Item
                        name={["address", "number"]}
                        label="Número"
                        rules={[
                          { required: true, message: "Número obrigatório" },
                        ]}
                      >
                        <Input placeholder="Número" />
                      </Form.Item>
                    </Space>
                  </Form.Item>

                  <Form.Item label=" " noStyle colon={false}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={updatingUser}
                    >
                      Salvar cliente
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </>
          )}
        </MainContent>
      </>
    </AppLayout>
  );
};

export default EditCustomer;
