import axios, { AxiosHeaders, AxiosInstance } from "axios";
import * as jose from "jose";

export default class Api {
  private baseRequest: AxiosInstance;

  constructor() {
    this.baseRequest = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.baseRequest.interceptors.request.use(async (req) => {
      const secret = new TextEncoder().encode(
        process.env.REACT_APP_AUTH_SECRET_KEY
      );

      const alg = "HS256";

      const token = await new jose.SignJWT({ "urn:example:claim": true })
        .setProtectedHeader({ alg })
        .sign(secret);

      (req.headers as AxiosHeaders).set("Authorization", token);
      // (req.headers as AxiosHeaders).set("ngrok-skip-browser-warning", true);

      return req;
    });
  }

  public async get(url: string, queryParams?: any) {
    return this.baseRequest.get(url, { params: queryParams });
  }

  public async post(url: string, payload: any) {
    return this.baseRequest.post(url, payload);
  }

  public async patch(url: string, payload: any) {
    return this.baseRequest.patch(url, payload);
  }
}
