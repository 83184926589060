import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import PlansTable from "../../components/tables/plansTable";
import MainContent from "../../components/mainContent";
import { useNavigate } from "react-router-dom";
import { Plan } from "../../services/types";
import { getPlans } from "../../services/adminFunctions";
import { GetPlansResponse } from "../../services/responses";

const Plans: React.FC = () => {
  const navigate = useNavigate();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loadingPlans, setLoadingPlans] = useState(false);

  const getAllPlans = useCallback(async () => {
    setLoadingPlans(true);
    const getPlansRespose = await getPlans();
    if (!getPlansRespose.isError) {
      const res = (getPlansRespose as GetPlansResponse).data;
      setPlans(res);
    } else {
      // TODO tratar erro
    }
    setLoadingPlans(false);
  }, []);

  useEffect(() => {
    getAllPlans();
  }, [getAllPlans]);

  return (
    <AppLayout title="Planos">
      <MainContent>
        <>
          <div className={styles.header}>
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => navigate("/admin/plans/new")}
            >
              Adicionar plano
            </Button>
          </div>
          <PlansTable plans={plans} loading={loadingPlans} />
        </>
      </MainContent>
    </AppLayout>
  );
};

export default Plans;
