import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { ConfigProvider } from "antd";
import { antdTheme } from "./config/theme";
import { router } from "./router/router";
import locale from "antd/locale/pt_BR";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ConfigProvider theme={antdTheme} locale={locale}>
    <RouterProvider router={router} />
  </ConfigProvider>
);
