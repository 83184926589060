import Icon from "@ant-design/icons/lib/components/Icon";
import React from "react";
import { colors } from "../../config/theme";
import { GiSoccerBall } from "react-icons/gi";

const iconSize = 50;

const Loader: React.FC = () => {
  return (
    <div
      style={{
        background: colors.primary,
        borderRadius: "70px",
        width: 45,
        height: 45,
        display: "grid",
        placeContent: "center",
      }}
    >
      <Icon
        spin
        style={{
          animationDuration: "1.3s",
        }}
        size={iconSize}
        component={() => (
          <GiSoccerBall size={iconSize} color={colors.softGray} />
        )}
      />
    </div>
  );
};

export default Loader;
