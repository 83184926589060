import React, { useState } from "react";
import { Button, Drawer } from "antd";
import styles from "./styles.module.css";
import {
  ArrowLeftOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import VideoCartItem from "../videoCartItem";
import { Alert, Space } from "antd/lib";
import { getCartItems, getLoggedUser } from "../../auth/userService";
import { createPurchase } from "../../services/userFunctions";
import { useNavigate } from "react-router-dom";
import { CreatePurchaseResponse } from "../../services/responses";
import { PurchaseData } from "../../services/types";
import { encrypt } from "../../services/crypto";

type ShoppingCartProps = {
  onUpdate?: () => void;
};

const ShoppingCart: React.FC<ShoppingCartProps> = ({ onUpdate }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [, updateState] = React.useState();
  const session = getLoggedUser();
  const userIsLogged = session && typeof session !== "string";

  const forceUpdate = React.useCallback(() => updateState({} as any), []);
  const [creatingPurchase, setCreatingPurchase] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    onUpdate && onUpdate();
  };

  const initializePurchase = async () => {
    setCreatingPurchase(true);
    if (session && typeof session !== "string") {
      const cartItems = getCartItems();
      const totalPrice = cartItems.reduce(
        (total, video) => total + parseFloat(String(video.videoPrice)),
        0
      );
      const totalDiscount =
        (totalPrice * parseFloat(String(session.discount))) / 100;
      const finalPrice = totalPrice - totalDiscount;

      const payload = {
        userId: session.id,
        discount: totalDiscount,
        finalPrice: parseFloat(finalPrice.toFixed(2)),
        items: getCartItems().length,
        price: parseFloat(totalPrice.toFixed(2)),
        videos: [...getCartItems().map((item) => item.id)],
      };

      const createPurchaseResponse = await createPurchase(payload);

      if (!createPurchaseResponse.isError) {
        const res = createPurchaseResponse as CreatePurchaseResponse;
        if (res.status === "success") {
          navigate(`/videos/me?purchase=FINISHED`);
        } else {
          // window.open(
          //   `https://www.mercadopago.com.br/checkout/v1/redirect?preference_id=${res.purchaseId}`
          // );
          const payloadWithPurchaseData: PurchaseData = {
            ...payload,
            cartItems: [...getCartItems()],
            payer: session,
            preferenceId: res.preferenceId,
            externalReference: res.externalReference,
          };
          navigate(`/purchase/${await encrypt(payloadWithPurchaseData)}`);
        }
      } else {
        //TODO tratar erro
      }
    }
    setCreatingPurchase(false);
  };

  return (
    <>
      <Button
        onClick={showDrawer}
        className={styles.filtersWrapper}
        icon={<ShoppingCartOutlined />}
        type="text"
      ></Button>
      <Drawer
        placement="right"
        title="Carrinho"
        onClose={onClose}
        open={open}
        footer={
          userIsLogged && (
            <div className={styles.actions}>
              <div className="sumary">
                Total:{" "}
                <strong>
                  {getCartItems()
                    .reduce(
                      (total, video) =>
                        total + parseFloat(String(video.videoPrice)),
                      0
                    )
                    .toFixed(2)}
                </strong>
              </div>
              <div className="sumary">
                Descontos ({session.discount}%):{" "}
                <strong>
                  -{" "}
                  {(
                    getCartItems().reduce(
                      (sum, video) =>
                        sum + parseFloat(String(video.videoPrice)),
                      0
                    ) *
                    (parseFloat(String(session.discount)) / 100)
                  ).toFixed(2)}
                </strong>
              </div>
              <div className="sumary">
                Valor a pagar:{" "}
                <strong>
                  {" "}
                  {(
                    getCartItems().reduce(
                      (total, video) =>
                        total + parseFloat(String(video.videoPrice)),
                      0
                    ) -
                    getCartItems().reduce(
                      (sum, video) =>
                        sum + parseFloat(String(video.videoPrice)),
                      0
                    ) *
                      (parseFloat(String(session.discount)) / 100)
                  ).toFixed(2)}
                </strong>
              </div>
              <div className="buttons">
                <Button
                  size="large"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => onClose()}
                >
                  Voltar
                </Button>
                <Button
                  size="large"
                  type="primary"
                  icon={<SearchOutlined />}
                  onClick={() => initializePurchase()}
                  loading={creatingPurchase}
                >
                  Finalizar compra
                </Button>
              </div>
            </div>
          )
        }
      >
        {getCartItems().length > 0 ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            {getCartItems().map((video) => (
              <VideoCartItem
                video={video}
                removeCallback={() => forceUpdate()}
              />
            ))}
          </Space>
        ) : (
          <Alert showIcon type="warning" message="Nenhum vídeo adicionado" />
        )}
      </Drawer>
    </>
  );
};

export default ShoppingCart;
