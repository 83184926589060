import React, { useState } from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import MainContent from "../../components/mainContent";
import { Tabs } from "antd/lib";
import { PlaySquareFilled, TagFilled } from "@ant-design/icons";
import VideoSalesTable from "../../components/tables/videoSalesTable";
import PlansSalesTable from "../../components/tables/plansSalesTable";

const Sales: React.FC = () => {
  const [activeContent, setActiveContent] = useState<"videos" | "plans">(
    "videos"
  );

  return (
    <AppLayout title="Vendas">
      <>
        <MainContent
          header={
            <Tabs
              className={styles.tabs}
              hideAdd
              onChange={(props) =>
                setActiveContent(props as "videos" | "plans")
              }
              activeKey={activeContent}
              type="card"
              size="large"
              items={[
                {
                  label: "Vídeos",
                  key: "videos",
                  icon: <PlaySquareFilled />,
                },
                {
                  label: "Planos",
                  key: "plans",
                  icon: <TagFilled />,
                },
              ]}
            />
          }
        >
          {activeContent === "videos" ? (
            <VideoSalesTable />
          ) : (
            <PlansSalesTable />
          )}
        </MainContent>
      </>
    </AppLayout>
  );
};

export default Sales;
