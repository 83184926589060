import { Result } from "antd";
import { Button } from "antd/lib";
import React from "react";
import { useNavigate } from "react-router-dom";

// import { Container } from './styles';

const FourOhFOur: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="403"
      title="Sem acesso"
      subTitle="Desculpe, você não está autorizado a visualizar esta página"
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Voltar ao início
        </Button>
      }
    />
  );
};

export default FourOhFOur;
