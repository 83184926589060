import React from "react";

import { Avatar, Dropdown, Layout, MenuProps, theme, Typography } from "antd";
import styles from "./styles.module.css";
import {
  SettingOutlined,
  LogoutOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { removeLoggedUser } from "../../auth/userService";
const { Header } = Layout;

type HeaderProps = {
  title?: string;
};

const AppHeader: React.FC<HeaderProps> = ({ title }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      label: "Painel usuário",
      key: "/videos",
      icon: <RollbackOutlined />,
      onClick: () => navigate("/videos"),
    },
    {
      label: "Configurações de admin",
      key: "/admin/settings",
      icon: <SettingOutlined />,
      onClick: () => navigate("/admin/settings"),
    },
    {
      label: "Sair",
      key: "/logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        removeLoggedUser();
        navigate("/");
      },
    },
  ];

  return (
    <Header className={styles.header} style={{ background: colorBgContainer }}>
      {title && (
        <Typography.Title style={{ margin: 0 }} level={5}>
          {title}
        </Typography.Title>
      )}
      <div className="actions">
        <Dropdown menu={{ items }}>
          <Avatar className={styles.avatar} src="/avatar.svg" />
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
