import React from "react";
import { Layout, Menu } from "antd";
import styles from "./styles.module.css";
import AppHeader from "../header";
import { IoMdFootball } from "react-icons/io";
import { RiPriceTagFill, RiShieldFill, RiUserSearchFill } from "react-icons/ri";
import { SiCashapp } from "react-icons/si";

import Icon from "@ant-design/icons/lib/components/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfigProvider } from "antd/lib";
import { antdTheme } from "../../config/theme";
const { Footer, Sider } = Layout;
const iconSize = 26;
const items = [
  { icon: <RiUserSearchFill />, label: "Clientes", key: "/admin/customers" },
  { icon: <IoMdFootball />, label: "Arenas", key: "/admin/arenas" },

  { icon: <RiPriceTagFill />, label: "Planos", key: "/admin/plans" },

  { icon: <RiShieldFill />, label: "Admins", key: "/admin/admins" },

  { icon: <SiCashapp />, label: "Vendas", key: "/admin/sales" },
].map((data) => ({
  key: data.key,
  icon: <Icon size={iconSize} component={() => data.icon} />,
  label: data.label,
}));

type AppLayoutProps = {
  title?: string;
  children: JSX.Element;
};

const AppLayout: React.FC<AppLayoutProps> = ({ children, title }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <ConfigProvider theme={antdTheme}>
      <Layout className="main">
        <Sider breakpoint="lg" collapsedWidth="0">
          <div className={styles.logo}>
            <img alt="logo" src="/logo-full-white.png" height={"100%"} />
          </div>
          <Menu
            onSelect={(ev) => navigate(ev.key)}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[location.pathname]}
            items={items}
          />
        </Sider>
        <Layout>
          <AppHeader title={title} />

          {children}

          <Footer
            style={{ textAlign: "center", fontSize: "11px", padding: 12 }}
          >
            Futlance {new Date().getFullYear()} &copy; CNPJ: 12.456.789/00001-12
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AppLayout;
