import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/adminLayout";
import CustomerTable from "../../components/tables/customerTable";
import styles from "./styles.module.css";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import MainContent from "../../components/mainContent";
import { useNavigate } from "react-router-dom";
import { User } from "../../services/types";
import { getUsers } from "../../services/adminFunctions";
import { GetUsersResponse } from "../../services/responses";

const Customers: React.FC = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const getAllUsers = useCallback(async () => {
    setLoadingUsers(true);
    const getAllUsersResponse = await getUsers();
    if (!getAllUsersResponse.isError) {
      const res = (getAllUsersResponse as GetUsersResponse).data;
      setUsers(res);
    } else {
      // TODO tratar erro
    }
    setLoadingUsers(false);
  }, []);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  return (
    <AppLayout title="Clientes">
      <MainContent>
        <>
          <div className={styles.header}>
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => navigate("/admin/customers/new")}
            >
              Adicionar cliente
            </Button>
          </div>
          <CustomerTable users={users} loading={loadingUsers} />
        </>
      </MainContent>
    </AppLayout>
  );
};

export default Customers;
