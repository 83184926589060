export const content = [
  {
    id: "1435345-345345-35555",
    customer: "Jean",
    purchasedAt: "2024-08-13 23:15",
    value: 12,
    quantity: 1,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "2553455-535353-5353",
    customer: "José Augusto",
    purchasedAt: "2024-08-01 18:15",
    value: 14,
    quantity: 1,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "34534534-5345345-534588",
    customer: "Augustinho Carrara",
    purchasedAt: "2024-08-16 08:15",
    value: 88,
    quantity: 3,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "4553456-567678-67999",
    customer: "Maria Clara",
    purchasedAt: "2024-08-17 15:45",
    value: 35,
    quantity: 2,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "5432323-543543-54323",
    customer: "Bruno Silva",
    purchasedAt: "2024-08-12 12:30",
    value: 22,
    quantity: 1,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "6567657-657657-67567",
    customer: "Ana Paula",
    purchasedAt: "2024-08-14 09:10",
    value: 45,
    quantity: 2,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "7687676-768768-78787",
    customer: "Carlos Henrique",
    purchasedAt: "2024-08-11 11:20",
    value: 60,
    quantity: 3,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "8787678-878787-87878",
    customer: "Fernanda Lima",
    purchasedAt: "2024-08-10 14:55",
    value: 18,
    quantity: 1,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "9878787-987987-98798",
    customer: "Gabriel Santos",
    purchasedAt: "2024-08-09 17:35",
    value: 70,
    quantity: 4,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "1089898-098098-09809",
    customer: "Helena Costa",
    purchasedAt: "2024-08-08 10:05",
    value: 27,
    quantity: 1,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "1198989-198198-19819",
    customer: "Isabela Moreira",
    purchasedAt: "2024-08-07 13:25",
    value: 40,
    quantity: 2,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "1210989-298298-29829",
    customer: "Joaquim Almeida",
    purchasedAt: "2024-08-06 16:45",
    value: 55,
    quantity: 3,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "1311989-398398-39839",
    customer: "Laura Nogueira",
    purchasedAt: "2024-08-05 19:15",
    value: 33,
    quantity: 2,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "1412989-498498-49849",
    customer: "Marcelo Vieira",
    purchasedAt: "2024-08-04 09:40",
    value: 50,
    quantity: 3,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "1513989-598598-59859",
    customer: "Nina Silva",
    purchasedAt: "2024-08-03 12:20",
    value: 75,
    quantity: 4,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "1614989-698698-69869",
    customer: "Otávio Monteiro",
    purchasedAt: "2024-08-02 15:00",
    value: 28,
    quantity: 1,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "1715989-798798-79879",
    customer: "Paula Menezes",
    purchasedAt: "2024-08-01 18:15",
    value: 65,
    quantity: 3,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "1816989-898898-89889",
    customer: "Ricardo Lopes",
    purchasedAt: "2024-07-31 20:45",
    value: 80,
    quantity: 4,
    status: "Canceled" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
  {
    id: "1917989-998998-99899",
    customer: "Sofia Costa",
    purchasedAt: "2024-07-30 22:35",
    value: 90,
    quantity: 5,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Plan" as "Single" | "Plan",
  },
  {
    id: "2018989-109109-10910",
    customer: "Tiago Rocha",
    purchasedAt: "2024-07-29 08:50",
    value: 38,
    quantity: 2,
    status: "Purchased" as "Purchased" | "Canceled",
    purchasedFrom: "Single" as "Single" | "Plan",
  },
];
