import { UploadFile } from "antd/lib";
import { RcFile } from "antd/lib/upload";

export function rcFileToBase64(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export const convertRcFileToUploadFile = (rcFile: any): UploadFile => {
  const uploadFile: UploadFile = {
    uid: rcFile.uid,
    name: rcFile.name,
    size: rcFile.size,
    type: rcFile.type,
    lastModified: rcFile.lastModified,
    lastModifiedDate: rcFile.lastModifiedDate,
    originFileObj: rcFile,
  };

  return uploadFile;
};
