import React from "react";
import AppLayout from "../../components/adminLayout";
import styles from "./styles.module.css";
import { Button } from "antd";
import { PlusCircleFilled } from "@ant-design/icons";
import AdminsTable from "../../components/tables/adminsTable";
import MainContent from "../../components/mainContent";
import { useNavigate } from "react-router-dom";

const Users: React.FC = () => {
  const navigate = useNavigate();

  return (
    <AppLayout title="Administradores">
      <MainContent>
        <>
          <div className={styles.header}>
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              onClick={() => navigate("/admin/admins/new")}
            >
              Adicionar adminstrador
            </Button>
          </div>
          <AdminsTable />
        </>
      </MainContent>
    </AppLayout>
  );
};

export default Users;
