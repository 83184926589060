import {
  Avatar,
  Badge,
  Dropdown,
  MenuProps,
  Popconfirm,
  Radio,
} from "antd/lib";
import React from "react";
import styles from "./styles.module.css";
import ShoppingCart from "../shoppingCart";
import {
  CrownFilled,
  LogoutOutlined,
  PlaySquareFilled,
  TagsFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getCartItems,
  getLoggedUser,
  removeLoggedUser,
} from "../../auth/userService";

type UserHeaderProps = {
  openCartPopoverState: boolean;
  setOpenCartPopoverState: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserHeader: React.FC<UserHeaderProps> = ({
  openCartPopoverState,
  setOpenCartPopoverState,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const session = getLoggedUser();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({} as any), []);

  const items: MenuProps["items"] = [
    {
      label: "Meu perfil",
      key: "/user/profile",
      icon: <UserOutlined />,
      onClick: () => navigate("/user/profile"),
    },
    {
      label: "Meus vídeos",
      key: "/videos/me",
      icon: <PlaySquareFilled />,
      onClick: () => navigate("/videos/me"),
    },
    {
      label: "Assinar um plano",
      key: "/plans",
      icon: <TagsFilled />,
      onClick: () => navigate("/videos/plans"),
    },
    {
      label: "Sair",
      key: "/logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        removeLoggedUser();
        navigate("/");
      },
    },
  ];

  const adminPanelOption = {
    label: "Painel Admin",
    key: "/admin/customers",
    icon: <CrownFilled />,
    onClick: () => {
      navigate("/admin/customers");
    },
  };

  return (
    <header className={styles.userHeader}>
      <div className={styles.logo}>
        <Link to={"/"}>
          <img alt="logo" src="/logo-full.png" height={"100%"} />
        </Link>
      </div>

      <Radio.Group
        value={location.pathname === "/videos/me" ? "my" : "all"}
        className={styles.myFilter}
        onChange={(e) =>
          e.target.value === "all"
            ? navigate("/videos")
            : navigate("/videos/me")
        }
      >
        <Radio.Button value="all">Todos os vídeos</Radio.Button>
        <Radio.Button value="my">Meus vídeos</Radio.Button>
      </Radio.Group>

      <div className="actions">
        <Badge count={getCartItems().length}>
          <Popconfirm
            title="Vídeo adicionado ao carrinho!"
            description="Para finalizar a compra, basta acessar aqui!"
            open={openCartPopoverState}
            onOpenChange={() => setOpenCartPopoverState(false)}
            okButtonProps={{ className: "hidden-button" }}
            cancelButtonProps={{ className: "hidden-button" }}
            // onConfirm={() => {
            //   setOpenCartPopoverState(false);
            // }}
            // onCancel={() => setOpenCartPopoverState(false)}
            // okText="Ver meu carrinho"
            // cancelText="Continuar comprando"
          >
            <ShoppingCart onUpdate={() => forceUpdate()} />
          </Popconfirm>
        </Badge>
        <Dropdown
          menu={{
            items:
              session &&
              typeof session !== "string" &&
              session.type === "PRIVILEGED"
                ? [adminPanelOption, ...items]
                : items,
          }}
        >
          <Avatar
            className={styles.avatar}
            src={
              session && typeof session !== "string" && session?.avatarImg
                ? session.avatarImg
                : "/avatar.svg"
            }
          />
        </Dropdown>
      </div>
    </header>
  );
};

export default UserHeader;
