import { ThemeConfig } from "antd";

export const colors = {
  primary: "#00993f",
  primaryDark: "#005326",
  gray: "#949494",
  error: "#d4380d",
  softGray: "#eeefe5",
  defaultDark: "#001529",
  defaultLight: "#f2fcf2",
  linkColor: "#006db2",
  warningColor: "#fa8c16",
};

export const antdTheme: ThemeConfig = {
  token: {
    // Seed Token
    colorPrimary: colors.primary,
    colorLink: colors.linkColor,

    // Alias Token
    colorBgContainer: "white",
  },
};
