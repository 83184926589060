import React from "react";
import styles from "./styles.module.css";
import { Content } from "antd/lib/layout/layout";
import { theme } from "antd/lib";
type MainContentProps = {
  children: JSX.Element;
  header?: JSX.Element;
};
const MainContent: React.FC<MainContentProps> = ({ children, header }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Content className={styles.content}>
      {header && header}
      <div
        className={styles.main}
        style={{
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {children}
      </div>
    </Content>
  );
};

export default MainContent;
