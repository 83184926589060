import React from "react";
import { Plan } from "../../services/types";
import styles from "./styles.module.css";
import { Card } from "antd";
import { Button } from "antd/lib";
type PlanCardProps = {
  plan: Plan;
};

const PlanCard: React.FC<PlanCardProps> = ({ plan }) => {
  return (
    <Card
      title={plan.name}
      actions={[
        <Button size="large" type="primary" block>
          Comprar agora
        </Button>,
      ]}
      style={{ width: 300 }}
      className={styles.planCard}
    >
      <div className={styles.planContent}>
        <div className={styles.priceWrapper}>
          <div className="price">R$ {plan.price}</div>
          <div className="price-frequency">
            {plan.frequency === "ANUAL" ? "/Ano" : "/Mês"}
          </div>
        </div>
        <div className={styles.videosQuantityTag}>
          {plan.videosQuantity} Vídeos!
        </div>
        <div className={styles.planTitle}>{plan.description} </div>
        <div className={styles.planDescription}>{plan.priceDescription}</div>
      </div>
    </Card>
  );
};

export default PlanCard;
