export const content = [
  {
    id: "1",
    username: "admin",
    email: "josealmeida@gmail.com",
    createdAt: "2024-08-13",
    type: "Admin" as "Admin" | "Arena",
  },
  {
    id: "2",
    username: "José Augusto",
    email: "joseaugusto@gmail.com",
    createdAt: "2024-08-01",
    type: "Admin" as "Admin" | "Arena",
  },
  {
    id: "3",
    username: "Augustinho Carrara",
    email: "augustinho@gmail.com",
    createdAt: "2024-08-16",
    type: "Arena" as "Admin" | "Arena",
  },
];
