import React, { useCallback, useRef, useState } from "react";
import {
  SearchOutlined,
  CalendarFilled,
  TagFilled,
  OrderedListOutlined,
  SortDescendingOutlined,
  SortAscendingOutlined,
  FilterFilled,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  Badge,
  Button,
  Card,
  DatePicker,
  Input,
  Space,
  Table,
  Tag,
} from "antd";
import type { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { content } from "./content";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

interface DataType {
  id: string;
  customer: string;
  purchasedAt: string;
  value: number;
  nextBillingDate: string;
  plan: string;
  status: "Active" | "Canceled" | "CanceledByReceiver";
}

type DataIndex = keyof DataType;

const data: DataType[] = content;

const PlansSalesTable: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [purchaseDatesFilter, setPurchaseDatesFilter] = useState<
    null | (dayjs.Dayjs | null)[]
  >(null);
  const [nextBillDatesFilter, setNextBillDatesFilter] = useState<
    null | (dayjs.Dayjs | null)[]
  >(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onPurchaseRangeChange = (dates: null | (dayjs.Dayjs | null)[]) => {
    if (dates) {
      setPurchaseDatesFilter([dates[0], dates[1]]);
    } else {
      setPurchaseDatesFilter(null);
    }
  };

  const onBillsRangeChange = (dates: null | (dayjs.Dayjs | null)[]) => {
    if (dates) {
      setNextBillDatesFilter([dates[0], dates[1]]);
    } else {
      setNextBillDatesFilter(null);
    }
  };

  const getFilteredData = useCallback(() => {
    if (purchaseDatesFilter !== null || nextBillDatesFilter !== null) {
      const allData = [...data];
      return allData
        .filter((souceItem) => {
          if (purchaseDatesFilter !== null) {
            return dayjs(souceItem.purchasedAt).isBetween(
              dayjs(purchaseDatesFilter[0]).set("hour", 0),
              dayjs(purchaseDatesFilter[1]).set("hour", 24)
            );
          } else {
            return true;
          }
        })
        .filter((souceItem) => {
          if (nextBillDatesFilter !== null) {
            return dayjs(souceItem.nextBillingDate).isBetween(
              dayjs(nextBillDatesFilter[0]).set("hour", 0),
              dayjs(nextBillDatesFilter[1]).set("hour", 24)
            );
          } else {
            return true;
          }
        });
    } else {
      return data;
    }
  }, [purchaseDatesFilter, nextBillDatesFilter]);

  const getStatusText = (
    status: "Active" | "Canceled" | "CanceledByReceiver"
  ) => {
    switch (status) {
      case "Active":
        return "Ativa";
      case "Canceled":
        return "Cancelada";
      case "CanceledByReceiver":
        return "Cancelada pelo recebedor";

      default:
        return "Cancelada";
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "Cliente",
      dataIndex: "customer",
      key: "customer",
      ...getColumnSearchProps("customer", "cliente"),
      sorter: (a, b) => defaultSorter(a.customer, b.customer),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Preço",
      dataIndex: "value",
      key: "value",
      sorter: (a, b) => a.value - b.value,
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      sortDirections: ["descend", "ascend"],
      render: (val) =>
        val.toLocaleString("pt-br", { style: "currency", currency: "BRL" }),
    },
    {
      title: "Plano",
      dataIndex: "plan",
      key: "plan",
      ...getColumnSearchProps("plan", "plano"),
      sorter: (a, b) => defaultSorter(a.plan, b.plan),
      sortDirections: ["descend", "ascend"],
      render: (value) => (
        <Tag color="orange" icon={<TagFilled />}>
          {value}
        </Tag>
      ),
    },
    {
      title: "Status da compra",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Ativa", value: "Active" },
        { text: "Cancelada", value: "Canceled" },
        { text: "Cancelada pelo recebedor", value: "CanceledByReceiver" },
      ],
      sortIcon: (props) => (
        <Button
          size="small"
          type="primary"
          icon={getSortIcon(props.sortOrder)}
        ></Button>
      ),
      filterIcon: (filtered) => (
        <Badge size="small" count={filtered ? 1 : 0}>
          <Button size="small" type="primary" icon={<FilterFilled />} />
        </Badge>
      ),
      onFilter: (value, record) => record.status === (value as string),
      sorter: (a, b) => defaultSorter(a.status, b.status),
      sortDirections: ["descend", "ascend"],
      render: (value) => (
        <Tag color={value === "Active" ? "green" : "red"}>
          {getStatusText(value)}
        </Tag>
      ),
    },
    {
      title: "Data da compra",
      dataIndex: "purchasedAt",
      key: "purchasedAt",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setPurchaseDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onPurchaseRangeChange}
            value={
              purchaseDatesFilter && [
                purchaseDatesFilter[0],
                purchaseDatesFilter[1],
              ]
            }
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={purchaseDatesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY HH:mm"),
    },
    {
      title: "Próxima cobrança",
      dataIndex: "nextBillingDate",
      key: "nextBillingDate",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setNextBillDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onBillsRangeChange}
            value={
              nextBillDatesFilter && [
                nextBillDatesFilter[0],
                nextBillDatesFilter[1],
              ]
            }
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={nextBillDatesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY HH:mm"),
    },
  ];

  return (
    <Table
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
        filterReset: "Limpar",
        filterConfirm: "Filtrar",
      }}
      columns={columns}
      dataSource={getFilteredData()}
      scroll={{ x: 1200 }}
      bordered
      pagination={{ pageSize: 10 }}
    />
  );
};

export default PlansSalesTable;
