import React, { useCallback, useRef, useState } from "react";
import {
  SearchOutlined,
  CalendarFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  OrderedListOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import type { InputRef, TableColumnsType, TableColumnType } from "antd";
import {
  Avatar,
  Badge,
  Button,
  Card,
  DatePicker,
  Input,
  Space,
  Table,
} from "antd";
import type { FilterDropdownProps, SortOrder } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";
import styles from "./styles.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { Field } from "../../../services/types";
import Loader from "../../loader";
import { useNavigate } from "react-router-dom";
dayjs.extend(isBetween);

type DataIndex = keyof Field;
type FieldsTableProps = {
  fields: Field[];
  loading: boolean;
};
const FieldsTable: React.FC<FieldsTableProps> = ({ fields, loading }) => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [datesFilter, setDatesFilter] = useState<null | (dayjs.Dayjs | null)[]>(
    null
  );

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const defaultSorter = (a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const getSortIcon = (orderType: SortOrder) => {
    switch (orderType) {
      case "ascend":
        return <SortAscendingOutlined />;
      case "descend":
        return <SortDescendingOutlined />;

      default:
        return <OrderedListOutlined />;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    placeholder: string
  ): TableColumnType<Field> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${placeholder}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>

          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchText("");
              setSearchedColumn(dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </div>
    ),
    sortIcon: (props) => (
      <Button
        size="small"
        type="primary"
        icon={getSortIcon(props.sortOrder)}
      ></Button>
    ),
    filterIcon: (filtered: boolean) => (
      <Badge size="small" count={filtered ? 1 : 0}>
        <Button size="small" type="primary" icon={<SearchOutlined />}>
          Buscar
        </Button>
      </Badge>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onRangeChange = (
    dates: null | (dayjs.Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDatesFilter([dates[0], dates[1]]);
    } else {
      setDatesFilter(null);
    }
  };

  const getFilteredData = useCallback(() => {
    if (datesFilter !== null) {
      const allData = [...fields];
      return allData.filter((souceItem) =>
        dayjs(souceItem.createdAt).isBetween(
          dayjs(datesFilter[0]).add(-1, "day"),
          dayjs(datesFilter[1]).add(1, "day")
        )
      );
    } else {
      return fields;
    }
  }, [datesFilter, fields]);

  const columns: TableColumnsType<Field> = [
    {
      title: "Campo",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description", "campo"),
      sorter: (a, b) => defaultSorter(a.description, b.description),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Arena",
      dataIndex: "logoImg",
      key: "logoImg",
      render: (value: string) => (
        <Space>
          <Avatar alt="arena logo" shape="square" src={value} />
        </Space>
      ),
    },

    {
      title: "Equipamento",
      dataIndex: "equipment",
      key: "equipment",
      ...getColumnSearchProps("equipment", "equipamento"),
      sorter: (a, b) => defaultSorter(a.equipment, b.equipment),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Data de criação",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: () => (
        <Card
          actions={[
            <div className={styles.dateActions}>
              <Button
                type="link"
                size="small"
                onClick={() => setDatesFilter(null)}
              >
                Limpar datas
              </Button>
            </div>,
          ]}
          title="Filtrar por data"
        >
          <DatePicker.RangePicker
            onChange={onRangeChange}
            value={datesFilter && [datesFilter[0], datesFilter[1]]}
          />
        </Card>
      ),
      filterIcon: () => (
        <Badge size="small" count={datesFilter !== null ? 1 : 0}>
          <Button size="small" type="primary" icon={<CalendarFilled />} />
        </Badge>
      ),
      render: (value) => dayjs(value).format("DD/MM/YY"),
    },
    {
      title: "Ações",
      dataIndex: "actions",
      key: "actions",
      render: (value, record) => (
        <>
          <Button
            type="primary"
            icon={<EditFilled />}
            onClick={() => navigate(`/admin/fields/edit/${record.id}`)}
            style={{ marginRight: 8 }}
          />
          <Button
            type="primary"
            danger
            icon={<DeleteFilled />}
            onClick={(e) => e.stopPropagation()}
          />
        </>
      ),
    },
  ];

  return (
    <Table
      className={styles.fieldsTable}
      onRow={(r) => ({
        onClick: () => navigate(`/admin/fields/edit/${r.id}`),
      })}
      loading={{
        spinning: loading,
        indicator: (
          <div>
            <Loader />
          </div>
        ),
      }}
      locale={{
        triggerAsc: "Ordem crescente",
        triggerDesc: "Ordem decrescente",
        cancelSort: "Ordem padrão",
        filterReset: "Limpar",
        filterConfirm: "Filtrar",
      }}
      columns={columns}
      dataSource={getFilteredData()}
      scroll={{ x: 1200 }}
      bordered
      pagination={{ pageSize: 8 }}
    />
  );
};

export default FieldsTable;
