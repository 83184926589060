export const content = [
  {
    id: "1435345-345345-35555",
    customer: "Jean Carlos",
    value: 29.9,
    purchasedAt: "2024-08-13 23:15",
    nextBillingDate: "2024-09-13 23:15",
    plan: "Light",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "1435345-345345-35555",
    customer: "Marcos Andrade",
    value: 29.9,
    purchasedAt: "2024-08-18 21:15",
    nextBillingDate: "2024-09-18 21:15",
    plan: "Premium",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "1435345-345345-35555",
    customer: "Fátima Bernardes",
    value: 29.9,
    purchasedAt: "2024-08-18 21:15",
    nextBillingDate: "2024-09-18 21:15",
    plan: "Premium",
    status: "CanceledByReceiver" as
      | "Active"
      | "Canceled"
      | "CanceledByReceiver",
  },
  {
    id: "2435345-345345-35555",
    customer: "Lucas Silva",
    value: 19.9,
    purchasedAt: "2024-08-12 19:10",
    nextBillingDate: "2024-09-12 19:10",
    plan: "Basic",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "3435345-345345-35555",
    customer: "Ana Clara",
    value: 29.9,
    purchasedAt: "2024-08-15 22:30",
    nextBillingDate: "2024-09-15 22:30",
    plan: "Premium",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "4435345-345345-35555",
    customer: "Rafael Nogueira",
    value: 29.9,
    purchasedAt: "2024-08-16 20:20",
    nextBillingDate: "2024-09-16 20:20",
    plan: "Light",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "5435345-345345-35555",
    customer: "Juliana Paes",
    value: 29.9,
    purchasedAt: "2024-08-17 21:45",
    nextBillingDate: "2024-09-17 21:45",
    plan: "Premium",
    status: "CanceledByReceiver" as
      | "Active"
      | "Canceled"
      | "CanceledByReceiver",
  },
  {
    id: "6435345-345345-35555",
    customer: "Pedro Alvares",
    value: 19.9,
    purchasedAt: "2024-08-14 18:15",
    nextBillingDate: "2024-09-14 18:15",
    plan: "Basic",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "7435345-345345-35555",
    customer: "Sofia Andrade",
    value: 29.9,
    purchasedAt: "2024-08-19 22:10",
    nextBillingDate: "2024-09-19 22:10",
    plan: "Premium",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "8435345-345345-35555",
    customer: "Gabriel Costa",
    value: 29.9,
    purchasedAt: "2024-08-20 20:05",
    nextBillingDate: "2024-09-20 20:05",
    plan: "Light",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "9435345-345345-35555",
    customer: "Isabela Gomes",
    value: 29.9,
    purchasedAt: "2024-08-21 23:00",
    nextBillingDate: "2024-09-21 23:00",
    plan: "Premium",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "10435345-345345-35555",
    customer: "João Pedro",
    value: 19.9,
    purchasedAt: "2024-08-22 18:45",
    nextBillingDate: "2024-09-22 18:45",
    plan: "Basic",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "11435345-345345-35555",
    customer: "Manuela Freitas",
    value: 29.9,
    purchasedAt: "2024-08-23 21:25",
    nextBillingDate: "2024-09-23 21:25",
    plan: "Premium",
    status: "CanceledByReceiver" as
      | "Active"
      | "Canceled"
      | "CanceledByReceiver",
  },
  {
    id: "12435345-345345-35555",
    customer: "Tiago Lima",
    value: 29.9,
    purchasedAt: "2024-08-24 19:40",
    nextBillingDate: "2024-09-24 19:40",
    plan: "Light",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "13435345-345345-35555",
    customer: "Renata Souza",
    value: 29.9,
    purchasedAt: "2024-08-25 20:35",
    nextBillingDate: "2024-09-25 20:35",
    plan: "Premium",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "14435345-345345-35555",
    customer: "Victor Ferreira",
    value: 19.9,
    purchasedAt: "2024-08-26 22:00",
    nextBillingDate: "2024-09-26 22:00",
    plan: "Basic",
    status: "CanceledByReceiver" as
      | "Active"
      | "Canceled"
      | "CanceledByReceiver",
  },
  {
    id: "15435345-345345-35555",
    customer: "Bruna Lopes",
    value: 29.9,
    purchasedAt: "2024-08-27 21:10",
    nextBillingDate: "2024-09-27 21:10",
    plan: "Premium",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "16435345-345345-35555",
    customer: "Felipe Oliveira",
    value: 29.9,
    purchasedAt: "2024-08-28 23:15",
    nextBillingDate: "2024-09-28 23:15",
    plan: "Light",
    status: "Canceled" as "Active" | "Canceled" | "CanceledByReceiver",
  },
  {
    id: "17435345-345345-35555",
    customer: "Laura Almeida",
    value: 29.9,
    purchasedAt: "2024-08-29 19:50",
    nextBillingDate: "2024-09-29 19:50",
    plan: "Premium",
    status: "CanceledByReceiver" as
      | "Active"
      | "Canceled"
      | "CanceledByReceiver",
  },
  {
    id: "18435345-345345-35555",
    customer: "Eduardo Matos",
    value: 19.9,
    purchasedAt: "2024-08-30 20:40",
    nextBillingDate: "2024-09-30 20:40",
    plan: "Basic",
    status: "Active" as "Active" | "Canceled" | "CanceledByReceiver",
  },
];
