import * as jose from "jose";
import { PurchaseData, User } from "./types";

const secret = new TextEncoder().encode(
  process.env.REACT_APP_DATA_ENCRYPT_SECRET_KEY
);

export const encrypt = async (payload: any) => {
  const token = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg: "HS256", typ: "JWT" })
    .sign(secret);

  return token.toString();
};

export const decrypt = (text: string): string | User => {
  try {
    return jose.decodeJwt(text) as User;
  } catch (error) {
    return String(error);
  }
};

export const decryptPurchaseData = (text: string): string | PurchaseData => {
  try {
    return jose.decodeJwt(text) as PurchaseData;
  } catch (error) {
    return String(error);
  }
};
