import React, { useEffect, useState, useCallback } from "react";
import { Button, Drawer, Tag } from "antd";
import styles from "./styles.module.css";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import { ConfigProvider, DatePicker, Form, Select, Space } from "antd/lib";
import { City, ICity, IState, State } from "country-state-city";
import { TbSoccerField } from "react-icons/tb";
import { GiBrazil } from "react-icons/gi";
import { FaCalendar, FaCity } from "react-icons/fa";
import { IoMdFootball } from "react-icons/io";
import { VideoFiltersPayload } from "../../services/payloads";
import locale from "antd/locale/pt_BR";
import dayjs from "dayjs";
import { GetArenasResponse, GetFieldsResponse } from "../../services/responses";
import { getArenas, getFieds } from "../../services/adminFunctions";
import { Arena, Field } from "../../services/types";
import Avatar from "antd/lib/avatar/avatar";
import { colors } from "../../config/theme";
type VideoFiltersProps = {
  onFilter: (filters: VideoFiltersPayload) => void;
};

const VideoFilters: React.FC<VideoFiltersProps> = ({ onFilter }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [stateList, setStateList] = useState<IState[]>([]);
  const [cityList, setCityList] = useState<ICity[]>([]);
  const [filtersSelected, setFiltersSelected] = useState<
    VideoFiltersPayload | undefined
  >(undefined);
  const [arenaSelected, setArenaSelected] = useState<Arena | undefined>(
    undefined
  );
  const [fieldSelected, setFieldSelected] = useState<Field | undefined>(
    undefined
  );

  const onFinish = (values: any) => {
    const payload: VideoFiltersPayload = {
      arenaId: values.arenaId,
      city: values.city,
      fieldId: values.fieldId,
      state: values.state,
      startingDate:
        values.period && dayjs(values.period[0]).format("YYYY-MM-DD"),
      endingDate: values.period && dayjs(values.period[1]).format("YYYY-MM-DD"),
    };

    const arenaSelected = arenas.find((arena) => arena.id === values.arenaId);
    const fieldSelected = fields.find((field) => field.id === values.fieldId);

    setFiltersSelected(payload);
    setArenaSelected(arenaSelected);
    setFieldSelected(fieldSelected);

    onFilter(payload);
  };

  const getAllStates = useCallback(() => {
    setStateList(State.getStatesOfCountry("BR"));
  }, []);

  const getCities = useCallback((stateCode: string) => {
    setCityList(City.getCitiesOfState("BR", stateCode));
  }, []);

  const [arenas, setArenas] = useState<Arena[]>([]);
  const [fields, setFields] = useState<Field[]>([]);
  const [loadingArenas, setLoadingArenas] = useState(false);
  const [loadingFields, setLoadingFields] = useState(false);

  const getAllArenas = useCallback(async () => {
    setLoadingArenas(true);
    const getAllArenasResponse = await getArenas();
    if (!getAllArenasResponse.isError) {
      const res = (getAllArenasResponse as GetArenasResponse).data;
      setArenas(res);
    }
    setLoadingArenas(false);
  }, []);

  const getAllFields = useCallback(async () => {
    setLoadingFields(true);
    const getFieldsResponse = await getFieds();
    if (!getFieldsResponse.isError) {
      const res = (getFieldsResponse as GetFieldsResponse).data;
      setFields(res);
    }
    setLoadingFields(false);
  }, []);

  useEffect(() => {
    getAllArenas();
    getAllFields();
    getAllStates();
  }, [getAllStates, getAllArenas, getAllFields]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ConfigProvider locale={locale}>
      <div className={styles.filterButtonWrapper} onClick={showDrawer}>
        <button onClick={showDrawer} className={styles.filtersWrapper}>
          <div className={styles.filterItemMobile}>
            <div className={styles.filterItemTitle}>Buscar vídeos</div>
            <div className={styles.filterItemText}>
              Localização, Arena, Campo ou Data
            </div>
          </div>
          <div className={styles.filterItem}>
            <div className={styles.filterItemTitle}>Buscar por localização</div>
            <div className={styles.filterItemText}>Estado ou cidade</div>
          </div>
          <div className={styles.filterItem}>
            <div className={styles.filterItemTitle}>Buscar por local</div>
            <div className={styles.filterItemText}>
              Encontrar uma arena ou campo
            </div>
          </div>
          <div className={styles.filterItem}>
            <div className={styles.filterItemTitle}>Buscar por período</div>
            <div className={styles.filterItemText}>
              Data em que o vídeo foi gravado
            </div>
          </div>
          <Button
            type="primary"
            shape="circle"
            size="large"
            icon={<SearchOutlined />}
          ></Button>
        </button>
        <div className={styles.filtersSelectWrapper}>
          {(filtersSelected?.endingDate ||
            filtersSelected?.startingDate ||
            filtersSelected?.city ||
            filtersSelected?.state ||
            fieldSelected !== undefined ||
            arenaSelected !== undefined) && (
            <Space wrap size={"small"}>
              <div>Buscando por:</div>
              {filtersSelected?.state && (
                <Tag icon={<GiBrazil />} color={colors.primary}>
                  {filtersSelected.state}
                </Tag>
              )}
              {filtersSelected?.city && (
                <Tag icon={<FaCity />} color={colors.primary}>
                  {filtersSelected.city}
                </Tag>
              )}
              {arenaSelected && (
                <Tag
                  icon={
                    <Avatar
                      shape="square"
                      size={"small"}
                      src={arenaSelected.logoImg}
                    />
                  }
                  color={colors.primary}
                >
                  {arenaSelected.name}
                </Tag>
              )}
              {fieldSelected && (
                <Tag icon={<TbSoccerField />} color={colors.primary}>
                  {fieldSelected.description}
                </Tag>
              )}
              {filtersSelected?.endingDate && filtersSelected?.startingDate && (
                <Tag icon={<FaCalendar />} color={colors.primary}>
                  {dayjs(filtersSelected.startingDate).format("DD/MM/YYYY")} à{" "}
                  {dayjs(filtersSelected.endingDate).format("DD/MM/YYYY")}
                </Tag>
              )}
            </Space>
          )}
        </div>
      </div>

      <Drawer
        placement="left"
        title="Buscar vídeos"
        onClose={onClose}
        open={open}
        footer={
          <div className={styles.actions}>
            <Button
              size="large"
              icon={<DeleteFilled />}
              onClick={() => form.resetFields()}
            >
              Limpar filtros
            </Button>
            <Button
              size="large"
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => {
                form.submit();
                setOpen(false);
              }}
            >
              Buscar
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          className={styles.form}
          name="complex-form"
          onFinish={onFinish}
          labelCol={{ span: 32 }}
          wrapperCol={{ span: 32 }}
          layout="vertical"
        >
          <Form.Item
            label={
              <>
                <GiBrazil style={{ marginRight: 8 }} /> Buscar por estado
              </>
            }
          >
            <Form.Item noStyle name="state" label="Estado">
              <Select
                placeholder="Estado"
                showSearch
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="new-state"
                options={stateList.map((c) => ({
                  label: c.name,
                  value: c.isoCode,
                }))}
                onChange={(option) => getCities(option)}
                allowClear
              ></Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <FaCity style={{ marginRight: 8 }} /> Buscar por cidade
              </>
            }
          >
            <Form.Item noStyle name="city" label="Cidade">
              <Select
                placeholder="Cidade"
                showSearch
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                autoComplete="new-state"
                options={cityList.map((c) => ({
                  label: c.name,
                  value: c.name,
                }))}
                allowClear
              ></Select>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <IoMdFootball style={{ marginRight: 8 }} /> Buscar por arena
              </>
            }
          >
            <Form.Item noStyle name="arenaId" label="Arena">
              <Select
                placeholder="Nome do arena"
                showSearch
                loading={loadingArenas}
                options={arenas.map((a) => ({
                  ...a,
                  label: a.name,
                  value: a.id,
                }))}
                optionFilterProp="label"
                optionRender={(option) => (
                  <Space>
                    <span role="img" aria-label={option.data.label}>
                      <Avatar
                        size="small"
                        shape="square"
                        src={option.data.logoImg}
                      />
                    </span>
                    {option.data.name}
                  </Space>
                )}
                allowClear
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <TbSoccerField style={{ marginRight: 8 }} /> Buscar por campo
              </>
            }
          >
            <Form.Item noStyle name="fieldId" label="Campo">
              <Select
                placeholder="Nome do campo"
                showSearch
                loading={loadingFields}
                options={fields.map((f) => ({
                  ...f,
                  label: f.description,
                  value: f.id,
                }))}
                optionFilterProp="label"
                allowClear
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            label={
              <>
                <FaCalendar style={{ marginRight: 8 }} /> Buscar por período
              </>
            }
          >
            <Form.Item noStyle name="period" label="Período">
              <DatePicker.RangePicker
                format={{ format: "DD/MM/YYYY" }}
                allowClear
              />
            </Form.Item>
          </Form.Item>
        </Form>
      </Drawer>
    </ConfigProvider>
  );
};

export default VideoFilters;
