import React from "react";
import styles from "./styles.module.css";
import { Button, Tag } from "antd/lib";
import { DeleteFilled } from "@ant-design/icons";
import { Video } from "../../services/types";
import dayjs from "dayjs";
import { getLoggedUser, removeCartItem } from "../../auth/userService";
import { colors } from "../../config/theme";

type VideoCartItemProps = {
  video: Video;
  removeCallback: () => void;
  hideRemove?: boolean;
};

const VideoCartItem: React.FC<VideoCartItemProps> = ({
  video,
  removeCallback,
  hideRemove,
}) => {
  const session = getLoggedUser();
  const userIsLogged = session && typeof session !== "string";

  const removeFromCart = () => {
    removeCartItem(video);
    removeCallback();
  };

  return (
    <div className={styles.cartItemWrapper}>
      {userIsLogged && (
        <Tag color={colors.primary} className={styles.cartItemPrice}>
          R${" "}
          {(
            parseFloat(String(video.videoPrice)) -
            parseFloat(String(video.videoPrice)) *
              (parseFloat(String(session.discount)) / 100)
          ).toFixed(2)}
        </Tag>
      )}
      <img alt="preview do vídeo" height={60} src={video.preview} />
      <div className={styles.cartItemTexts}>
        <div className={styles.cartItemTitle}>
          {video.arenaName} - {video.fieldDescription}
        </div>
        <div className={styles.cartItemDescription}>
          {dayjs(video.recordedAt).format("DD/MM/YYYY")} às{" "}
          {dayjs(video.recordedAt).format("HH:mm")}
        </div>
      </div>

      {!hideRemove && (
        <Button
          onClick={() => removeFromCart()}
          style={{ width: 50 }}
          icon={<DeleteFilled />}
          danger
          type="text"
        ></Button>
      )}
    </div>
  );
};

export default VideoCartItem;
